import React, { useState, useRef } from 'react';
import TinyEditor from '../../Editor/TinyEditor';
import { FaUpload } from 'react-icons/fa'; // Import the upload icon from react-icons

const AddFormation = () => {
    const [formData, setFormData] = useState({
        titre: '',
        description: '',
        image: null,
        prix: '',
        currency:'€',
        plateforme: 'Azure (Microsoft)',
        thematique: 'Cloud & Infrastructure',
        details: '',
        duree: '1 jour / 4h',
        support: null, // New field for the PDF file
    });

    const styles = {
        fileInput: {
            display: 'none'
        },
        customFileLabel: {
            display: 'inline-block',
            cursor: 'pointer',
            padding: '10px 20px',
            backgroundColor: '#f0f0f0',
            borderRadius: '4px',
            border: '1px solid #ccc',
            textAlign: 'center'
        },
        icon: {
            marginRight: '10px'
        }
    };

    const [editorError, setEditorError] = useState(false);
    const [imageError, setimageError] = useState(false);
    const [supportError, setsupportError] = useState(false);

    const [courseImg, setCourseImg] = useState(null); // State for holding the image URL
    const fileInputRef = useRef(null);
    const supportInputRef = useRef(null); // Reference for the support input

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setFormData({
                ...formData,
                image: file,
            });
            setCourseImg(URL.createObjectURL(file)); // Generate a URL for the uploaded image
        }
    };

    const handleSupportChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setFormData({
                ...formData,
                support: file,
            });
        }
    };

    const handleEditorStateChange = (newEditorState) => {
        setFormData({
            ...formData,
            details: newEditorState,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (formData.details === '') {
            setEditorError(true);
            return;
        } else {
            setEditorError(false);
        }

        if (!formData.image) {
            setimageError(true);
            return;
        } else {
            setimageError(false);
        }

        if (!formData.support) {
            setsupportError(true);
            return;
        } else {
            setsupportError(false);
        }

        const apiEndpoint = process.env.REACT_APP_API_URL + '/formations/formations';
        const formDataToSubmit = new FormData();

        for (const key in formData) {
            formDataToSubmit.append(key, formData[key]);
        }

        try {
            const response = await fetch(apiEndpoint, {
                method: 'POST',
                body: formDataToSubmit,
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            // Show success message
            alert('Formation added successfully');

            // Reset form
            setFormData({
                titre: '',
                description: '',
                image: null,
                prix: '',
                plateforme: 'Azure (Microsoft)',
                thematique: 'Cloud & Infrastructure',
                details: '',
                duree: '1 jour / 4h',
                support: null, // Reset the support field
            });
            setCourseImg(null); // Reset the image

            // Reset the file inputs
            if (fileInputRef.current) {
                fileInputRef.current.value = null;
            }
            if (supportInputRef.current) {
                supportInputRef.current.value = null;
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    return (
        <React.Fragment>
            <div className="rs-my-account pt-90 pb-100 md-pt-60 md-pb-80">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 md-mb-50">
                            <h2 className="title pb-30 md-pb-14">Créer une formation</h2>
                            <div className="rs-login">
                                <form className="form-group mb-30" onSubmit={handleSubmit}>
                                    <div className="form-part">
                                        <div className="wraper mb-30">
                                            <label>Titre<span>*</span></label>
                                            <input type="text" className="form-control-mod" name="titre" value={formData.titre} onChange={handleChange} required />
                                        </div>
                                        <div className="wraper mb-30">
                                            <label>Description<span>*</span></label>
                                            <textarea className="form-control" name="description" value={formData.description} onChange={handleChange} required />
                                        </div>
                                        <div className="wraper mb-30">
                                            <label>Prix<span>*</span></label>
                                            <div className="d-flex align-items-center">
                                                <input
                                                    type="text"
                                                    name="prix"
                                                    className="form-control-mod"
                                                    value={formData.prix}
                                                    onChange={handleChange}
                                                    required
                                                />
                                                <select
                                                    name="currency"
                                                    className="form-control ms-2"
                                                    onChange={(e) => setFormData({ ...formData, currency: e.target.value })}
                                                    required
                                                >
                                                    <option value="EUR">€</option>
                                                    <option value="USD">$</option>
                                                </select>
                                            </div>
                                        </div>

                                        <div className="wraper mb-30">
                                            <label>Plateforme<span>*</span></label>
                                            <select className="form-control" name="plateforme" value={formData.plateforme} onChange={handleChange} required>
                                                <option value="Azure (Microsoft)">Azure (Microsoft)</option>
                                                <option value="GCP (Google)">GCP (Google)</option>
                                                <option value="Kubernetes (Linux foundation)">Kubernetes (Linux foundation)</option>
                                            </select>
                                        </div>
                                        <div className="wraper mb-30">
                                            <label>Thématique<span>*</span></label>
                                            <select className="form-control" name="thematique" value={formData.thematique} onChange={handleChange} required>
                                                <option value="Cloud & Infrastructure">Cloud & Infrastructure</option>
                                                <option value="Data & IA">Data & IA</option>
                                                <option value="DevOps & DevSecOps">DevOps & DevSecOps</option>
                                                <option value="Cyber Securité">Cyber Securité</option>
                                            </select>
                                        </div>
                                        <div className="wraper mb-30">
                                            <label>Détails<span>*</span></label>
                                            <TinyEditor
                                                value={formData.details}
                                                height={500}
                                                menubar={true}
                                                plugins=""
                                                toolbar=""
                                                handleEditorChange={handleEditorStateChange}
                                            />
                                            {editorError && <p style={{ color: 'red' }}>Le champ détails est obligatoire.</p>}
                                        </div>
                                        <div className="wraper mb-30">
                                            <label>Image<span>*</span></label>
                                            <input type="file" className="form-control-mod" name="image" onChange={handleImageChange} ref={fileInputRef} style={styles.fileInput} />
                                            <label
                                                htmlFor="image"
                                                style={styles.customFileLabel}
                                                onClick={() => fileInputRef.current.click()}
                                            >
                                                <FaUpload style={styles.icon} /> Choisir un fichier
                                            </label>
                                            {imageError && <p style={{ color: 'red' }}>Le champ image est obligatoire.</p>}

                                            {courseImg && (
                                                <div className="img-part">
                                                    <img src={courseImg} alt={formData.titre} style={{ width: '350px', height: '170px' }} />
                                                </div>
                                            )}
                                        </div>
                                        <div className="wraper mb-30">
                                            <label>Durée<span>*</span></label>
                                            <select className="form-control" name="duree" value={formData.duree} onChange={handleChange} required>
                                                <option value="1 jour / 4h">1 jour / 4h</option>
                                                <option value="1 jour / 7h">1 jour / 7h</option>
                                                <option value="3 jours / 21h">3 jours / 21h</option>
                                                <option value="3 jours / 28h">3 jours / 28h</option>
                                            </select>
                                        </div>
                                        <div className="wraper mb-30">
                                            <label>Support de la formation<span>*</span></label>
                                            <input
                                                type="file"
                                                className="form-control-mod"
                                                name="support"
                                                onChange={handleSupportChange}
                                                ref={supportInputRef}
                                                style={styles.fileInput}
                                            />
                                            <label
                                                htmlFor="support"
                                                style={styles.customFileLabel}
                                                onClick={() => supportInputRef.current.click()}
                                            >
                                                <FaUpload style={styles.icon} /> Choisir un fichier
                                            </label>
                                            {supportError && <p style={{ color: 'red' }}>Support de la formation est obligatoire.</p>}
                                            {formData.support && <p>Fichier sélectionné: {formData.support.name}</p>} {/* Display the selected file name */}
                                        </div>
                                    </div>
                                    <div className="btns-part">
                                        <button className="btn-shop orange-color" type="submit">Créer</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default AddFormation;
