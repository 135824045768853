import React from 'react';
import { Link } from 'react-router-dom';

const SiteBreadcrumb = (props) => {
	const { breadcrumbsClass, innerClass, titleClass, pageTitle, courseDesc, support } = props;

	return (
		<div className={breadcrumbsClass ? breadcrumbsClass : 'bootcamp-section main-header-comp bgcolor-midnight-gradient'}>
			<div className={innerClass ? innerClass : 'breadcrumbs-text white-color'}>
				<h1 className={titleClass ? titleClass : 'page-title text-white'}>{pageTitle ? pageTitle : 'Breadcrumbs'}</h1>
				<p className="h5-style text-regular text-white">{courseDesc}</p>
				<a href={`${process.env.REACT_APP_API_URL}/uploads/${support}`} className="hero-button white-button pop-in-syllabus w-button"> 
					Télécharger le programme
				</a>
			</div>
		</div>
	);
}

export default SiteBreadcrumb;
