import React from 'react';
import { Link } from 'react-router-dom';

const SiteBreadcrumb = (props) => {
	const { breadcrumbsClass, innerClass, titleClass, pageTitle, parentCategory, pageCategory, pageName, breadcrumbsImg } = props;

	return (
		<div className={breadcrumbsClass ? breadcrumbsClass : 'bootcamp-section main-header-comp bgcolor-midnight-gradient'}>
{/* 			<div className="breadcrumbs-img">
				<img 
					src={breadcrumbsImg} 
					alt="Breadcrumbs Image" 
				/>
			</div> */}
			<div className={innerClass ? innerClass : 'breadcrumbs-text white-color'}>
				<h1 className={titleClass ? titleClass : 'page-title text-white'}>{pageTitle ? pageTitle : 'Breadcrumbs'}</h1>
				<ul>
					<li>
						<span className="h5-style text-regular text-white">Formations, certifications et masterclass, autour des sujets technologiques majeurs actuels et émergeants.</span>
					</li>
				</ul>
			</div>
		</div>
	);
}

export default SiteBreadcrumb;




