import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Header from '../../../components/Layout/Header/Header';
import Footer from '../../../components/Layout/Footer/Footer';
import OffWrap from '../../../components/Layout/Header/OffWrap';
import SearchModal from '../../../components/Layout/Header/SearchModal';
import Newsletter from '../../../components/Common/Newsletter';
import SiteBreadcrumb from './Breadcumb';
import DisplayEditor from "../../Editor/DisplayEditor";

// Image
import Logo from '../../../assets/img/logo/csa.svg';
import footerLogo from '../../../assets/img/logo/csa.svg';
import bannerbg from '../../../assets/img/breadcrumbs/2.jpg';

const CourseSingle = () => {
    const { courseTitle } = useParams();
    const [course, setCourse] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [activeTab, setActiveTab] = useState('INTER');

    const revertTitle = (title) => {
        return title.replace(/-/g, ' ');
    };

    const apiEndpoint = `${process.env.REACT_APP_API_URL}/formations/formations/getOne?title=${revertTitle(courseTitle)}`;

    useEffect(() => {
        const fetchCourse = async () => {
            try {
                const response = await fetch(apiEndpoint);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                setCourse(data);
            } catch (error) {
                setError(error);
            } finally {
                setLoading(false);
            }
        };

        fetchCourse();
    }, [apiEndpoint]);

    if (loading) {
        return <p>Loading...</p>;
    }

    if (error) {
        return <p>Error: {error.message}</p>;
    }

    if (!course) {
        return <p>No course found.</p>;
    }

    return (
        <React.Fragment>
            <OffWrap />
            <Header
                parentMenu='course'
                headerNormalLogo={Logo}
                headerStickyLogo={Logo}
                CanvasLogo={Logo}
                mobileNormalLogo={Logo}
                CanvasClass="right_menu_togle hidden-md"
                headerClass="full-width-header header-style1 home8-style4"
                TopBar='enable'
                TopBarClass="topbar-area home8-topbar"
                emailAddress='cloudiasys.academy.yt@gmail.com'
                Location='78 AVENUE DES CHAMPS ELYSEES 75008 PARIS '
            />

            {/* breadcrumb-area-start */}
            <SiteBreadcrumb
                pageTitle={course.titre}
                courseDesc={course.description}
                pageName="Course Details"
                breadcrumbsImg={bannerbg}
                support={course.support}
            />
            {/* breadcrumb-area-start */}
            <div className="rs-my-account pt-90 pb-100 md-pt-60 md-pb-80">
                <div className="row justify-content-center">
                    <div className="col-10">
                        <div className="row">
                            <div className="col-lg-8">
                                <DisplayEditor value={course.details} />
                            </div>
                            <div className="col-lg-4">
                                <div className="tabs">
                                    <ul className="nav nav-tabs">
                                        <li className="nav-item">
                                            <button
                                                className={`nav-link ${activeTab === 'INTER' ? 'active' : ''}`}
                                                onClick={() => setActiveTab('INTER')}
                                            >
                                                INTER
                                            </button>
                                        </li>
                                        <li className="nav-item">
                                            <button
                                                className={`nav-link ${activeTab === 'INTRA' ? 'active' : ''}`}
                                                onClick={() => setActiveTab('INTRA')}
                                            >
                                                INTRA
                                            </button>
                                        </li>
                                    </ul>
                                    <div className="tab-content">
                                        <div className={`tab-pane ${activeTab === 'INTER' ? 'active' : ''}`}>
                                            <br />
                                            <strong><p><i className="fa fa-clock-o fa-1x mr-5 ms-3"></i>Durée: {course.durée}</p></strong>
                                            <strong><p><i className="fa fa-euro fa-1x mr-5 ms-3"></i>Prix: {course.prix}</p></strong>
                                            <div className="btn-part">
                                                <button className="readon2 orange-transparent-button">Contactez-nous</button>
                                            </div>                                        </div>
                                        <div className={`tab-pane ${activeTab === 'INTRA' ? 'active' : ''}`}>
                                            <br />
                                            <strong><p><i className="fa fa-clock-o fa-1x mr-5 ms-3"></i>Durée: {course.durée}</p></strong>
                                            <button className="readon2 orange-transparent-button">Contactez-nous</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Newsletter
                sectionClass="rs-newsletter style1 gray-bg orange-color mb--90 sm-mb-0 sm-pb-70"
                titleClass="title mb-0 white-color"
            />

            <Footer
                footerClass="rs-footer home9-style main-home"
                footerLogo={footerLogo}
            />
            <SearchModal />
        </React.Fragment>
    );
};

export default CourseSingle;
