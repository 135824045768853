import React from 'react';
import Header from '../../../components/Layout/Header/Header';
import OffWrap from '../../../components/Layout/Header/OffWrap';
import Footer from '../../../components/Layout/Footer/Footer';

import SearchModal from '../../../components/Layout/Header/SearchModal';
import Newsletter from '../../../components/Common/Newsletter';
import SiteBreadcrumb from '../Breadcumb';
// Image
import Logo from '../../../assets/img/logo/csa.svg';
import footerLogo from '../../../assets/img/logo/csa.svg';
import bannerbg from '../../../assets/img/breadcrumbs/breadcrumbs.jpg';
import Form from './Form'

const CreateFormation = () => {

    return (
        <React.Fragment>
            <OffWrap />
            <Header
                parentMenu='course'
                headerNormalLogo={Logo}
                headerStickyLogo={Logo}
                CanvasLogo={Logo}
                mobileNormalLogo={Logo}
                CanvasClass="right_menu_togle hidden-md"
                headerClass="full-width-header header-style1 home8-style4"
                TopBar='enable'
                TopBarClass="topbar-area home8-topbar"
                emailAddress='cloudiasys.academy.yt@gmail.com'
                Location='78 AVENUE DES CHAMPS ELYSEES 75008 PARIS '
            />

            <SiteBreadcrumb
                pageTitle="Nouvelle formation"
                innerClass="breadcrumbs-text"
                pageName="Formations"
                breadcrumbsImg={bannerbg}
            />

            <Form />
            <Newsletter
                sectionClass="rs-newsletter style1 orange-color mb--90 sm-mb-0 sm-pb-70"
                titleClass="title mb-0 white-color"
            />

            <Footer
                footerClass="rs-footer home9-style main-home"
                footerLogo={footerLogo}
            />
            <SearchModal />
        </React.Fragment>
    );
}

export default CreateFormation;