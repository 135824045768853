import React from 'react';
import ScrollToTop from '../../../components/Common/ScrollTop';

const CourseDetailsMain = () => {

    return (
        <React.Fragment>

            <div style={{ 'margin-top': '70px' }}>
                <div class="wrapper-1600 content-align-center mb-s3">
                    <div class="w-embed">
                        <h1 class="h2-style">
                            <span class="text-regular">Notre </span>
                            formation DevOps
                        </h1>
                    </div>
                </div>
                <div class="fixed-training-menu full-size-block bg-color-grey-50 hide-on-tablet menu-area">
                    <div class="hero-section width-100 plr-8vw justify-center no-padding">
                        <div class="wrapper-menu-slide">
                            <a href="#session" class="item-menu no-text-underline height-5vh">Sessions</a>
                            <a href="#rythme" class="item-menu no-text-underline height-5vh">Rythme</a>
                            <a href="#deroul" class="item-menu no-text-underline height-5vh">Programme</a>
                            <a href="#diplome" class="item-menu no-text-underline height-5vh">Diplôme</a>
                            <a href="#financement" class="item-menu no-text-underline height-5vh">Financement</a>
                            <a href="#admission" class="item-menu no-text-underline height-5vh">Admission</a>
                            <a href="#careercenter" class="item-menu no-text-underline height-5vh">Débouchés</a>
                            <a href="#faq" class="item-menu no-text-underline height-5vh w-condition-invisible">FAQ</a>
                        </div>
                    </div>
                </div>
            </div>
            <section class="hero-section mb-s1">
            <div class="wrapper-1600 flex-inline space-between no-margin-bottom vertical-on-phablet">
                <div class="col-47 full-size-on-phablet no-margin-bottom-phablet">
                    <img src="https://assets-global.website-files.com/60506edfbf3c1c49a536edc5/6511a828312e6a2106701c50_LA_CAPSULE_01-12-2022-133HD.webp"
                        loading="lazy"
                        srcset="https://assets-global.website-files.com/60506edfbf3c1c49a536edc5/6511a828312e6a2106701c50_LA_CAPSULE_01-12-2022-133HD-p-500.webp 500w, https://assets-global.website-files.com/60506edfbf3c1c49a536edc5/6511a828312e6a2106701c50_LA_CAPSULE_01-12-2022-133HD-p-800.webp 800w, https://assets-global.website-files.com/60506edfbf3c1c49a536edc5/6511a828312e6a2106701c50_LA_CAPSULE_01-12-2022-133HD-p-1080.webp 1080w, https://assets-global.website-files.com/60506edfbf3c1c49a536edc5/6511a828312e6a2106701c50_LA_CAPSULE_01-12-2022-133HD-p-1600.webp 1600w, https://assets-global.website-files.com/60506edfbf3c1c49a536edc5/6511a828312e6a2106701c50_LA_CAPSULE_01-12-2022-133HD-p-2000.webp 2000w, https://assets-global.website-files.com/60506edfbf3c1c49a536edc5/6511a828312e6a2106701c50_LA_CAPSULE_01-12-2022-133HD-p-2600.webp 2600w, https://assets-global.website-files.com/60506edfbf3c1c49a536edc5/6511a828312e6a2106701c50_LA_CAPSULE_01-12-2022-133HD.webp 3000w"
                        sizes="(max-width: 767px) 88vw, (max-width: 991px) 84vw, 36vw" alt="" class="cover-image" />
                    <div class="small-white-banner padding-20px small-white-banner-right">
                        <img src="https://assets-global.website-files.com/60506edfbf3c1c49a536edc5/65119b1d0b8b4e076a46417e_AWS%20logo.png"
                            loading="lazy" alt="" class="logo-aws" />
                    </div>
                </div>
                <div class="col-47 full-size-on-phablet phablet-margin-bottom-24">
                    <p class="h4-style mb-s2">Une formation en partenariat avec AWS</p>
                    <div class="flex-inline mb-s1">
                        <img src="https://assets-global.website-files.com/60506edfbf3c1c49a536edc5/6511a2b09bba29acde881171_Group.svg"
                            loading="lazy" alt="" class="icon-certificate" />
                        <div>Certification AWS Cloud Practitioner</div>
                    </div>
                    <div class="flex-inline">
                        <img src="https://assets-global.website-files.com/60506edfbf3c1c49a536edc5/6511a2b09bba29acde881171_Group.svg"
                            loading="lazy" alt="" class="icon-certificate" />
                        <div>Certification AWS Solutions Architect </div>
                    </div>
                </div>
            </div>
        </section>
        <div id="programme">
            <section class="hero-section">
                <div data-w-id="4dddebe5-019e-685e-f553-b8a7346f7aa5" 
                    class="wrapper-1600 content-align-center bg-purple-050 padding-block mb-s2">
                    <div class="h3-style mb-size-3">L &#x27;excellence de La Capsule</div>
                    <div class="flex-inline vertical-phablet">
                        <div class="flex-vertical-centered col-25">
                            <div data-w-id="4dddebe5-019e-685e-f553-b8a7346f7aaa" 
                                class="text-gradient-purple">
                                <div class="h1-style inline-block">8,8</div>
                                <div class="h4-style inline-block">/10</div>
                            </div>
                            <div class="stars-bloc">
                                <div data-w-id="4dddebe5-019e-685e-f553-b8a7346f7ab0" 
                                    class="dark-star-block"></div>
                                <div data-w-id="bd1f0c02-fe6c-71c7-47db-06d26d6ed374" 
                                    class="dark-star-block"></div>
                                <div data-w-id="6dc23940-5477-6b13-3789-48abcfa2bd16" 
                                    class="dark-star-block"></div>
                                <div data-w-id="ab162360-5959-67ca-e186-aa5f6b5e0218" 
                                    class="dark-star-block"></div>
                                <div data-w-id="33b08929-1d2d-ec6c-39ed-c6236db3d937" 
                                    class="dark-star-block"></div>
                            </div>
                            <div class="testimonial-service-block enlarged">
                                <div class="text-medium">Satisfaction*</div>
                                <div class="text-small">*En 2023 pour le programme Cloud &amp;DevOps Engineer</div>
                            </div>
                        </div>
                        <div class="vertical-divider rotate-mobile"></div>
                        <div class="flex-vertical-centered col-25">
                            <div data-w-id="4dddebe5-019e-685e-f553-b8a7346f7ab9" 
                                class="text-gradient-purple">
                                <div class="h4-style inline-block">+</div>
                                <div class="h1-style inline-block">1500</div>
                            </div>
                            <div class="stars-bloc">
                                <div data-w-id="4dddebe5-019e-685e-f553-b8a7346f7abf" 
                                    class="user-block"></div>
                                <div data-w-id="4dddebe5-019e-685e-f553-b8a7346f7ac0" 
                                    class="user-block"></div>
                                <div data-w-id="4dddebe5-019e-685e-f553-b8a7346f7ac1" 
                                    class="user-block"></div>
                                <div data-w-id="4dddebe5-019e-685e-f553-b8a7346f7ac2" 
                                    class="user-block"></div>
                                <div data-w-id="4dddebe5-019e-685e-f553-b8a7346f7ac3" 
                                    class="user-block"></div>
                            </div>
                            <div class="testimonial-service-block enlarged">
                                <div class="text-medium">Une large communauté d &#x27;alumnis</div>
                            </div>
                        </div>
                        <div class="vertical-divider rotate-mobile"></div>
                        <a href="/job-report" class="flex-vertical-centered col-25 w-inline-block">
                            <div data-w-id="4dddebe5-019e-685e-f553-b8a7346f7ac9" 
                                class="text-gradient-purple">
                                <div class="h1-style inline-block">94</div>
                                <div class="h4-style inline-block">%</div>
                            </div>
                            <div class="stars-bloc">
                                <div data-w-id="63c01718-cf52-f42d-0769-f00e2bf799a0"  class="gauge">
                                    <div data-w-id="1a1046d5-2c55-4535-709c-b9a28892c17a"
                                        class="gauge-90"></div>
                                </div>
                            </div>
                            <div class="testimonial-service-block enlarged">
                                <div class="text-medium">Taux d &#x27;employabilité après une de nos formations.</div>
                            </div>
                        </a>
                    </div>
                    <a href="#" class="see-more hide-element w-inline-block">
                        <div class="text-medium">Voir les entreprises qui recrutent</div>
                        <img src="https://assets-global.website-files.com/60506edfbf3c1c49a536edc5/628604f0446fa09536bafb8d_Group%20681.svg"
                            loading="lazy" alt="" class="image-5" />
                    </a>
                </div>
            </section>
        </div>
        <div id="session">
            <section class="hero-section padding-w-off tablet-no-padding-top no-padding-top">
                <div class="wrapper-fullsize tablet-vertical">
                    <div class="col-50 relative-col tablet-full-size">
                        <div class="sticky-image-block fondateurs-cover">
                            <div class="absolute-contrast-ellipse"></div>
                            <img src="https://assets-global.website-files.com/60506edfbf3c1c49a536edc5/64df2c1fbcb55420e8a4ebd4_LA-CAPSULE_27-01-2022-123HD.webp"
                                loading="lazy" sizes="(max-width: 991px) 100vw, 50vw"
                                srcset="https://assets-global.website-files.com/60506edfbf3c1c49a536edc5/64df2c1fbcb55420e8a4ebd4_LA-CAPSULE_27-01-2022-123HD-p-500.webp 500w, https://assets-global.website-files.com/60506edfbf3c1c49a536edc5/64df2c1fbcb55420e8a4ebd4_LA-CAPSULE_27-01-2022-123HD-p-800.webp 800w, https://assets-global.website-files.com/60506edfbf3c1c49a536edc5/64df2c1fbcb55420e8a4ebd4_LA-CAPSULE_27-01-2022-123HD-p-1080.webp 1080w, https://assets-global.website-files.com/60506edfbf3c1c49a536edc5/64df2c1fbcb55420e8a4ebd4_LA-CAPSULE_27-01-2022-123HD-p-1600.webp 1600w, https://assets-global.website-files.com/60506edfbf3c1c49a536edc5/64df2c1fbcb55420e8a4ebd4_LA-CAPSULE_27-01-2022-123HD.webp 4777w"
                                alt="" class="cover-image hide-on-mobile" />
                            <img src="https://assets-global.website-files.com/60506edfbf3c1c49a536edc5/628de866a9c7fed3be5a937d_visual(1)%20(1).png"
                                loading="lazy" sizes="100vw"
                                srcset="https://assets-global.website-files.com/60506edfbf3c1c49a536edc5/628de866a9c7fed3be5a937d_visual(1)%20(1)-p-500.png 500w, https://assets-global.website-files.com/60506edfbf3c1c49a536edc5/628de866a9c7fed3be5a937d_visual(1)%20(1)-p-800.png 800w, https://assets-global.website-files.com/60506edfbf3c1c49a536edc5/628de866a9c7fed3be5a937d_visual(1)%20(1)-p-1080.png 1080w, https://assets-global.website-files.com/60506edfbf3c1c49a536edc5/628de866a9c7fed3be5a937d_visual(1)%20(1).png 1328w"
                                alt="" class="cover-image hide-on-desktop" />
                        </div>
                    </div>
                    <div class="col-50 tablet-full-size">
                        <div class="sticky-block-slide">
                            <p class="h3-style mt-24px mb-16px">Une approche par projet</p>
                            <p>Véritable signature de la pédagogie de La Capsule, l’approche par projet ou Project Based
                                Learning est fondamentale car elle permet de parfaitement intégrer les notions au
                                travers de mises en situations adaptées au rythme de votre apprentissage et assimiler
                                plus rapidement la méthodologie DevOps.</p>
                        </div>
                        <div class="sticky-block-slide">
                            <p href="https://www.lacapsule.academy/articles/suivre-une-formation-developpeur-web-le-parcours-daugustin-aujourdhui-developpeur-frontend-chez-smallable"
                                class="h3-style mt-24px mb-16px">Conçue par les meilleurs</p>
                            <p
                                href="https://www.lacapsule.academy/articles/suivre-une-formation-developpeur-web-le-parcours-daugustin-aujourdhui-developpeur-frontend-chez-smallable">
                                Une formation conçue par les meilleurs professionnels du DevOps.</p>
                            <div class="sticky-block-logos">
                             
                                <img src="https://assets-global.website-files.com/60506edfbf3c1c49a536edc5/628de8f7f71c223d0882b0e4_logo-Ubisoft%201.svg"
                                    loading="lazy" alt="" class="middle-logo" />

                            </div>
                        </div>
                        <div class="sticky-block-slide">
                            <p href="/code-for-business/full-time" class="h3-style mt-24px mb-16px">Un métier prisé</p>
                            <p href="/code-for-business/full-time">Le DevOps est un nouveau métier aujourd’hui très
                                prisé par les entreprises et les startups de la Tech. Véritable couteau suisse de l’IT,
                                le DevOps doit maîtriser de nombreuses compétences, à la fois techniques et humaines.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        <div id="metier">
            <div class="hero-section margin-bottom-6">
                <div class="wrapper-1600 content-align-center mb-s3">
                    <div class="w-embed">
                        <h2 class="h2-style">
                            <span class="text-regular">Pourquoi
                            </span>
                            devenir DevOps ?
                        </h2>
                    </div>
                </div>
                <div class="wrapper-1600 flex-vertical-centered padding-block bg-color-cherry-50">
                    <div class="h3-style mb-size-3 no-margin-bottom">Un métier qui recrute</div>
                    <div class="h4-style-subtitle">10 190 offres de Devops en Avril 2024</div>
                    <div class="tooltips-col">
                        <div class="tooltip">
                            <div data-w-id="7f619c72-0932-c1b2-c623-08f4d0341665" 
                                class="tooltip-number bg-color-purple-900 tablet-margin-bottom-12 tooltip-number-1">
                                <div class="h3-style text-white regular">1 574</div>
                            </div>
                            <div data-w-id="7f619c72-0932-c1b2-c623-08f4d0341668" 
                                class="tooltip-info purple-gradient tablet-padding-4">
                                <div class="h5-style text-white">Postes de DevOps</div>
                                <div class="text-white mobile-centered">Welcome to the Jungle France - Devops</div>
                            </div>
                        </div>
                        <div class="tooltip no-margin-bottom">
                            <div data-w-id="7f619c72-0932-c1b2-c623-08f4d034166e" 
                                class="tooltip-number bg-color-cherry-900 tablet-margin-bottom-12 tooltip-number-2">
                                <div class="h3-style text-white regular">8 616</div>
                            </div>
                            <div data-w-id="7f619c72-0932-c1b2-c623-08f4d0341671" 
                                class="tooltip-info cherry-gradient">
                                <div class="h5-style text-white">Postes de DevOps</div>
                                <div class="text-white mobile-centered">Linkedin France - Devops</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="hero-section bg-color-purple-50">
                <div class="wrapper-1600 no-margin flex-inline vertical-on-phablet same-height-cols">
                    <div data-w-id="7f619c72-0932-c1b2-c623-08f4d0341678" 
                        class="col-35 flex-vertical-left">
                        <div class="column-illustration">
                            <img src="https://assets-global.website-files.com/60506edfbf3c1c49a536edc5/62860f5c111c538ba16817a0_Slice%201.svg"
                                loading="lazy" alt="" class="col-illustration" />
                        </div>
                        <div class="text-gradient-purple margin-bottom-1">
                            <div class="h1-style inline-block smaller">65</div>
                            <div class="h4-style inline-block">% des offres</div>
                        </div>
                        <div class="h4-style">Une demande de juniors</div>
                        <div class="text-medium max-width-25">65% des offres sont des postes de DevOps junior*</div>
                        <div class="small-gray-text margin-top-2 max-width-25">*sur 103 offres d’emploi de DevOps entre
                            avril et mai 2023 en France sur Welcome to The Jungle et LinkedIn</div>
                    </div>
                    <div data-w-id="7f619c72-0932-c1b2-c623-08f4d0341686" 
                        class="vertical-divider rotate-mobile margin-2 height-30"></div>
                    <div data-w-id="7f619c72-0932-c1b2-c623-08f4d0341687" 
                        class="col-35 flex-vertical-left">
                        <div class="column-illustration">
                            <img src="https://assets-global.website-files.com/60506edfbf3c1c49a536edc5/62860f89d3e2e80764ad73ac_Slice%201(1).svg"
                                loading="lazy" alt="" class="col-illustration" />
                        </div>
                        <div class="text-gradient-purple margin-bottom-1">
                            <div class="h1-style inline-block smaller">94</div>
                            <div class="h4-style inline-block">% des entreprises</div>
                        </div>
                        <div class="h4-style">Une forte pénurie</div>
                        <div class="text-medium max-width-25">94% des entreprises ont des difficultés à recruter des
                            DevOps.*</div>
                        <div class="small-gray-text margin-top-2 max-width-25">* Selon l’enquête OpsRamp</div>
                    </div>
                    <div class="vertical-divider rotate-mobile margin-2 height-30"></div>
                    <div data-w-id="7f619c72-0932-c1b2-c623-08f4d0341696" 
                        class="col-35 flex-vertical-left">
                        <div class="column-illustration">
                            <img src="https://assets-global.website-files.com/60506edfbf3c1c49a536edc5/62860fe56bb6015637d714d1_Slice%201(2).svg"
                                loading="lazy" alt="" class="col-illustration" />
                        </div>
                        <div class="text-gradient-purple margin-bottom-1">
                            <div class="h1-style inline-block smaller">42 000</div>
                            <div class="h4-style inline-block">€ / an</div>
                        </div>
                        <div class="h4-style">Un 1er salaire élevé</div>
                        <div class="text-medium max-width-25">C’est le salaire médian pour un DevOps Junior*</div>
                        <div class="small-gray-text margin-top-2 max-width-25">*en France selon Talent.com</div>
                    </div>
                </div>
                <div class="mb-s4"></div>
                <div class="wrapper-1600 in-between-card">
                    <div data-w-id="7f619c72-0932-c1b2-c623-08f4d03416a6" 
                        class="white-banner vertical-on-phablet full-width">
                        <div>
                            <p class="h5-style text-regular no-margin">”Ce programme est le premier programme en France
                                à former des DevOps parfaitement opérationnels en seulement 10 semaines.”</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div id="rythme">
            <section class="hero-section mb-s2">
                <div class="wrapper-1600 pos-relative mt-s2">
                    <h2 class="h2-style text-regular content-align-center mt-24px">
                        Formez-vous à votre <strong class="text-semi-bold">rythme</strong>
                        <strong></strong>
                    </h2>
                </div>
                <div class="wrapper-1600 content-align-center mt-s1">
                    <div>
                        Choisissez le format qui vous correspond le mieux ! <br />Format bootcamp à temps plein ou cours
                        en ligne les soirs et weekend, nos programmes s &#x27;adaptent à votre situation !
                    </div>
                </div>
                <div class="job-cards">
                    <div data-w-id="0a5f32f4-f037-7cc2-cd53-240e7720dcec"  class="job-card">
                        <div class="job-img job-img-6">
                            <div class="job-card-title h3-style text-white">
                                Bootcamp<br />Full-time
                            </div>
                        </div>
                        <div class="flex-inline-left pr-2vw mt-s1 align-centered">
                            <div class="job-card-info ptbr-1vw">Le format Bootcamp. 10 semaines intensives de 9h à 18H
                                du lundi au vendredi.</div>
                            <div class="label purple-400-label ml-2vw mt-s1">2 mois</div>
                        </div>
                        <div class="job-card-info">
                            <div class="format-text mb-1vw">
                                <img src="https://assets-global.website-files.com/60506edfbf3c1c49a536edc5/642a9c82ec2dcc2552ff9d45_noun-star-5480646-5347EB.svg"
                                    loading="lazy" width="16" alt="" class="mr-size-1" />
                                <div>10 semaines de formation - 400h</div>
                            </div>
                            <div class="format-text mb-1vw">
                                <img src="https://assets-global.website-files.com/60506edfbf3c1c49a536edc5/642a9c82ec2dcc2552ff9d45_noun-star-5480646-5347EB.svg"
                                    loading="lazy" width="16" alt="" class="mr-size-1" />
                                <div>Du lundi au vendredi, de 9h à 18h</div>
                            </div>
                            <div class="format-text mb-1vw">
                                <img src="https://assets-global.website-files.com/60506edfbf3c1c49a536edc5/642a9c82ec2dcc2552ff9d45_noun-star-5480646-5347EB.svg"
                                    loading="lazy" width="16" alt="" class="mr-size-1" />
                                <div>En présentiel ou en distanciel</div>
                            </div>
                            <div class="format-text">
                                <img src="https://assets-global.website-files.com/60506edfbf3c1c49a536edc5/642a9c82ec2dcc2552ff9d45_noun-star-5480646-5347EB.svg"
                                    loading="lazy" width="16" alt="" class="mr-size-1" />
                                <div>Paris ou campus virtuel</div>
                            </div>
                        </div>
                    </div>
                    <div data-w-id="0a5f32f4-f037-7cc2-cd53-240e7720dcf3"  class="job-card">
                        <div class="job-img job-img-7">
                            <div class="job-card-title h3-style text-white">
                                Bootcamp<br />Part-time
                            </div>
                        </div>
                        <div class="flex-inline-left pr-2vw mt-s1 align-centered">
                            <div class="job-card-info ptbr-1vw">Vous n’êtes pas disponible en journée ? Apprenez à coder
                                le soir et le samedi.</div>
                            <div class="label purple-400-label ml-2vw mt-s1">6 mois</div>
                        </div>
                        <div class="job-card-info">
                            <div class="format-text mb-1vw">
                                <img src="https://assets-global.website-files.com/60506edfbf3c1c49a536edc5/642a9c82ec2dcc2552ff9d45_noun-star-5480646-5347EB.svg"
                                    loading="lazy" width="16" alt="" class="mr-size-1" />
                                <div>25 semaines de formation - 400h</div>
                            </div>
                            <div class="format-text mb-1vw">
                                <img src="https://assets-global.website-files.com/60506edfbf3c1c49a536edc5/642a9c82ec2dcc2552ff9d45_noun-star-5480646-5347EB.svg"
                                    loading="lazy" width="16" alt="" class="mr-size-1" />
                                <div>Les soirs de 18h30 à 21h30 et samedi matin</div>
                            </div>
                            <div class="format-text mb-1vw">
                                <img src="https://assets-global.website-files.com/60506edfbf3c1c49a536edc5/642a9c82ec2dcc2552ff9d45_noun-star-5480646-5347EB.svg"
                                    loading="lazy" width="16" alt="" class="mr-size-1" />
                                <div>16h par semaine - 4 live sessions</div>
                            </div>
                            <div class="format-text">
                                <img src="https://assets-global.website-files.com/60506edfbf3c1c49a536edc5/642a9c82ec2dcc2552ff9d45_noun-star-5480646-5347EB.svg"
                                    loading="lazy" width="16" alt="" class="mr-size-1" />
                                <div>Campus virtuel</div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div class="hero-section timeline-paddings bg-color-cherry-gradient">
                <div class="wrapper-1600 timeline-header">
                    <h3 class="h2-style text-white">La journée type de la formation DevOps</h3>
                </div>
                <div class="wrapper-920">
                    <div class="typical-day-timeline">
                        <div class="timeline-divider start"></div>
                        <div class="typical-day-hour">
                            <div class="h5-style">9h</div>
                        </div>
                    </div>
                    <div class="typical-day-content">
                        <div>
                            <div class="h4-style margin-bottom-8">Le cours</div>
                            <p class="text-small">
                                Rendez-vous tous les matins à 9H pour votre cours DevOps. Chaque jour vous verrez une
                                nouvelle notion. Pendant ce cours en direct, vous pourrez poser toutes les questions
                                nécessaires, échanger avec les autres élèves et votre teacher.<br />
                            </p>
                        </div>
                        <div class="typical-day-image-cover">
                            <img src="https://assets-global.website-files.com/60506edfbf3c1c49a536edc5/64df2d9bfd054a7b636ab281_LA-CAPSULE_28-03-2022-22HD%20(1).webp"
                                loading="lazy" sizes="(max-width: 479px) 100vw, 180px"
                                srcset="https://assets-global.website-files.com/60506edfbf3c1c49a536edc5/64df2d9bfd054a7b636ab281_LA-CAPSULE_28-03-2022-22HD%20(1)-p-500.webp 500w, https://assets-global.website-files.com/60506edfbf3c1c49a536edc5/64df2d9bfd054a7b636ab281_LA-CAPSULE_28-03-2022-22HD%20(1)-p-800.webp 800w, https://assets-global.website-files.com/60506edfbf3c1c49a536edc5/64df2d9bfd054a7b636ab281_LA-CAPSULE_28-03-2022-22HD%20(1)-p-1080.webp 1080w, https://assets-global.website-files.com/60506edfbf3c1c49a536edc5/64df2d9bfd054a7b636ab281_LA-CAPSULE_28-03-2022-22HD%20(1)-p-1600.webp 1600w, https://assets-global.website-files.com/60506edfbf3c1c49a536edc5/64df2d9bfd054a7b636ab281_LA-CAPSULE_28-03-2022-22HD%20(1)-p-2000.webp 2000w, https://assets-global.website-files.com/60506edfbf3c1c49a536edc5/64df2d9bfd054a7b636ab281_LA-CAPSULE_28-03-2022-22HD%20(1).webp 2500w"
                                alt="" class="cover-image" />
                        </div>
                    </div>
                </div>
                <div class="wrapper-920">
                    <div class="typical-day-timeline">
                        <div class="timeline-divider"></div>
                        <div class="typical-day-hour">
                            <div class="h5-style">11h</div>
                        </div>
                    </div>
                    <div class="typical-day-content">
                        <div>
                            <div class="h4-style margin-bottom-8">Le “step by step”</div>
                            <p class="text-small">A 11H00, c’est le moment de pratiquer! Vous travaillerez avec vos
                                buddies de la journée sur les challenges step by step qui vont vous permettre de
                                manipuler les notions du jour et de bien assimiler les nouveaux concepts.</p>
                        </div>
                    </div>
                </div>
                <div class="wrapper-920">
                    <div class="typical-day-timeline">
                        <div class="timeline-divider"></div>
                        <div class="typical-day-hour">
                            <div class="h5-style">13h</div>
                        </div>
                    </div>
                    <div class="typical-day-content">
                        <div>
                            <div class="h4-style">Pause !</div>
                        </div>
                    </div>
                </div>
                <div class="wrapper-920">
                    <div class="typical-day-timeline">
                        <div class="timeline-divider"></div>
                        <div class="typical-day-hour">
                            <div class="h5-style">14h</div>
                        </div>
                    </div>
                    <div class="typical-day-content">
                        <div>
                            <div class="h4-style margin-bottom-8">Les projets et challenges</div>
                            <p class="text-small">Chaque jour vous travaillerez sur un nouveau projet, vous pourrez par
                                exemple déployer un serveur automatiquement, créer des containers ou mettre en place un
                                système de monitoring pour vous familiariser avec les pratiques DevOps.</p>
                        </div>
                        <div class="typical-day-image-cover">
                            <img src="https://assets-global.website-files.com/60506edfbf3c1c49a536edc5/64df2ddf4fa3431205b83699_LA-CAPSULE_28-03-2022-150HD.webp"
                                loading="lazy" sizes="(max-width: 479px) 100vw, 180px"
                                srcset="https://assets-global.website-files.com/60506edfbf3c1c49a536edc5/64df2ddf4fa3431205b83699_LA-CAPSULE_28-03-2022-150HD-p-500.webp 500w, https://assets-global.website-files.com/60506edfbf3c1c49a536edc5/64df2ddf4fa3431205b83699_LA-CAPSULE_28-03-2022-150HD-p-800.webp 800w, https://assets-global.website-files.com/60506edfbf3c1c49a536edc5/64df2ddf4fa3431205b83699_LA-CAPSULE_28-03-2022-150HD-p-1080.webp 1080w, https://assets-global.website-files.com/60506edfbf3c1c49a536edc5/64df2ddf4fa3431205b83699_LA-CAPSULE_28-03-2022-150HD-p-1600.webp 1600w, https://assets-global.website-files.com/60506edfbf3c1c49a536edc5/64df2ddf4fa3431205b83699_LA-CAPSULE_28-03-2022-150HD-p-2000.webp 2000w, https://assets-global.website-files.com/60506edfbf3c1c49a536edc5/64df2ddf4fa3431205b83699_LA-CAPSULE_28-03-2022-150HD.webp 2500w"
                                alt="" class="cover-image" />
                        </div>
                    </div>
                </div>
                <div class="wrapper-920">
                    <div class="typical-day-timeline">
                        <div class="timeline-divider start end"></div>
                        <div class="typical-day-hour">
                            <div class="h5-style">17h</div>
                        </div>
                    </div>
                    <div class="typical-day-content">
                        <div>
                            <div class="h4-style margin-bottom-8">Le Live Code</div>
                            <p class="text-small">Votre teacher vous donnera sa solution du projet de l’après-midi lors
                                du Live Code. L’occasion de passer en revue l’ensemble des points difficiles de la
                                journée et de poser toutes vos questions.</p>
                        </div>
                    </div>
                </div>
                <div class="lottie-anim">
                    <div class="lottie-sprinkle-scalling" data-w-id="dd163fb1-d2d9-d247-9331-f7356e11bee6"
                        data-animation-type="lottie"
                        data-src="https://assets-global.website-files.com/60506edfbf3c1c49a536edc5/60ad29638a63ca1fbffc27d9_anim-fond-rouge.json"
                        data-loop="1" data-direction="1" data-autoplay="1" data-is-ix2-target="0" data-renderer="svg"
                        data-default-duration="21" data-duration="45"></div>
                </div>
            </div>
        </div>
        <div id="deroul" class="div-block-35">
            <div class="hero-section bg-color-grey-100 padding-top-12 padding-bottom-6">
                <div class="lottie-anim">
                    <div class="lottie-sprinkle-scalling" data-w-id="dac20b3c-3cf2-8abb-1cf7-330ac307901e"
                        data-animation-type="lottie"
                        data-src="https://assets-global.website-files.com/60506edfbf3c1c49a536edc5/60ad295c39c51be26acd73ea_anim-fond-blanc.json"
                        data-loop="1" data-direction="1" data-autoplay="1" data-is-ix2-target="0" data-renderer="svg"
                        data-default-duration="21" data-duration="40"></div>
                </div>
                <div class="wrapper-1600 content-align-center mb-s3">
                    <div class="w-embed">
                        <h2 class="h2-style">
                            <span class="text-gradient-cherry">Le programme
                            </span>
                            de la formation DevOps
                            </h2>
                    </div>
                </div>
                <div class="wrapper-1600 mb-s3">
                    <div class="custom-dropdown">
                        <div class="text-semi-bold program-drop-label">Systèmes et réseaux</div>
                        <div class="dropdown-chevron w-embed">
                            <svg width="14" height="11" viewBox="0 0 14 11" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M11.1851 2.13051e-06L2.8149 6.67019e-07C1.20202 3.85013e-07 0.252536 1.81108 1.16997 3.13763L5.35507 9.18899C6.15028 10.3388 7.84971 10.3388 8.64493 9.189L12.83 3.13763C13.7475 1.81109 12.798 2.41252e-06 11.1851 2.13051e-06Z"
                                    fill="#C5CED6" />
                            </svg>
                        </div>
                    </div>
                    <a href="#" class="hero-button purple-button tab-syllabus pop-in-syllabus w-button">Télécharger le
                        syllabus</a>
                    <div data-duration-in="300" data-duration-out="100" data-easing="ease-in-out"
                        data-current="infrastructure" class="program-tab w-tabs">
                        <div class="program-tab-menu w-tab-menu">
                            <a data-w-tab="systeme-reseaux" class="program-menu-link w-inline-block w-tab-link">
                                <h3 class="font-style-body tab-label">Systèmes et réseaux</h3>
                            </a>
                            <a data-w-tab="programmation-bdd" class="program-menu-link w-inline-block w-tab-link">
                                <h3 class="font-style-body tab-label">Programmation &amp;base de données</h3>
                            </a>
                            <a data-w-tab="pipeline-mise-en-prod" class="program-menu-link w-inline-block w-tab-link">
                                <h3 class="font-style-body tab-label">Pipeline de mise en production</h3>
                            </a>
                            <a data-w-tab="infrastructure"
                                class="program-menu-link w-inline-block w-tab-link w--current">
                                <h3 class="font-style-body tab-label">Infrastructure</h3>
                            </a>
                            <a data-w-tab="projet-final" class="program-menu-link w-inline-block w-tab-link">
                                <h3 class="font-style-body tab-label">Projet Final</h3>
                            </a>
                        </div>
                        <div class="program-tab-content w-tab-content">
                            <div data-w-tab="systeme-reseaux" class="program-tab-pane w-tab-pane">
                                <div class="mb-s1 w-richtext">
                                    <p>
                                        <strong>Terminal &amp;scripting - 4 jours</strong>
                                    </p>
                                    <p>
                                        Pour <a href="/articles/comment-devenir-devops">devenir DevOps</a>
                                        , vous devez d &#x27;abord apprendre les bases du métier. Cela commence par
                                        apprendre à manipuler un système d’exploitation via le terminal et créer des
                                        scripts shell pour analyser le système informatique et le
                                        réseau.<strong>‍</strong>
                                    </p>
                                    <p>
                                        <strong>Réseaux &amp;administration - 4 jours</strong>
                                    </p>
                                    <p>Vous comprendrez le fonctionnement d’un réseau informatique et connaîtrez les
                                        différents équipements qui le constituent. Apprenez à les configurer et
                                        administrer notamment avec une connexion SSH</p>
                                    <p>
                                        <strong>Sécurité - 2 jours</strong>
                                    </p>
                                    <p>Dans notre formation DevOps, vous apprendrez à reconnaître les attaques les plus
                                        courantes, en vous mettant dans la peau d’un hacker.  En mettant en œuvre ces
                                        attaques vous serez capable de vous en prémunir.</p>
                                </div>
                                <div class="flex-inline justify-start wrapp-on-phablet">
                                    <div class="stack-items-block">
                                        <div class="stack-logo-block">
                                            <img loading="lazy"
                                                src="https://assets-global.website-files.com/60506edfbf3c1c49a536edc5/6286334ae14d5b63ec522f44_Group%20488.svg"
                                                alt="" class="cover-image" />
                                        </div>
                                        <div class="text-semi-bold">Terminal</div>
                                    </div>
                                    <div class="stack-items-block">
                                        <div class="stack-logo-block">
                                            <img loading="lazy"
                                                src="https://assets-global.website-files.com/60506edfbf3c1c49a536edc5/628633bae07f8d36646432a2_architecture.svg"
                                                alt="" class="cover-image" />
                                        </div>
                                        <div class="text-semi-bold">Réseaux</div>
                                    </div>
                                    <div class="stack-items-block">
                                        <div class="stack-logo-block">
                                            <img sizes="(max-width: 479px) 24px, (max-width: 767px) 5vw, 4vw"
                                                srcset="https://assets-global.website-files.com/60506edfbf3c1c49a536edc5/6389de88444706b78a851d6b_noun-security-5345309-F94A56-p-500.png 500w, https://assets-global.website-files.com/60506edfbf3c1c49a536edc5/6389de88444706b78a851d6b_noun-security-5345309-F94A56-p-800.png 800w, https://assets-global.website-files.com/60506edfbf3c1c49a536edc5/6389de88444706b78a851d6b_noun-security-5345309-F94A56-p-1080.png 1080w, https://assets-global.website-files.com/60506edfbf3c1c49a536edc5/6389de88444706b78a851d6b_noun-security-5345309-F94A56.png 1200w"
                                                alt=""
                                                src="https://assets-global.website-files.com/60506edfbf3c1c49a536edc5/6389de88444706b78a851d6b_noun-security-5345309-F94A56.png"
                                                loading="lazy" class="cover-image" />
                                        </div>
                                        <div class="text-semi-bold">Sécurité</div>
                                    </div>
                                </div>
                            </div>
                            <div data-w-tab="programmation-bdd" class="program-tab-pane w-tab-pane">
                                <div class="mb-s1 w-richtext">
                                    <p>
                                        <strong>Bases de la programmation en Python - 3 jours</strong>
                                    </p>
                                    <p>Vous apprendrez à écrire des algorithmes manipulant des variables, des boucles,
                                        des conditions et des structures de données avec le langage de programmation
                                        Python.</p>
                                    <p>
                                        <strong>La base de données - 1 jour</strong>
                                    </p>
                                    <p>Maîtrisez la mise en place et l’administration d’une base de données PostgreSQL.
                                        Apprenez à créer une base de données relationnelle et écrivez vos requêtes pour
                                        réaliser des opérations CRUD.</p>
                                </div>
                                <div class="flex-inline justify-start wrapp-on-phablet">
                                    <div class="stack-items-block">
                                        <div class="stack-logo-block">
                                            <img loading="lazy"
                                                src="https://assets-global.website-files.com/60506edfbf3c1c49a536edc5/6389ddf68b179e57718f4101_5848152fcef1014c0b5e4967.png"
                                                alt="" class="cover-image" />
                                        </div>
                                        <div class="text-semi-bold">Python</div>
                                    </div>
                                    <div class="stack-items-block">
                                        <div class="stack-logo-block">
                                            <img loading="lazy"
                                                src="https://assets-global.website-files.com/60506edfbf3c1c49a536edc5/6389de22977eb03bb19764bf_584815fdcef1014c0b5e497a.png"
                                                alt="" class="cover-image" />
                                        </div>
                                        <div class="text-semi-bold">PostgreSQL</div>
                                    </div>
                                </div>
                            </div>
                            <div data-w-tab="pipeline-mise-en-prod" class="program-tab-pane w-tab-pane">
                                <div class="mb-s1 w-richtext">
                                    <p>
                                        <strong>Versionning - 3 jours</strong>
                                    </p>
                                    <p>
                                        <strong>‍</strong>
                                        Manipuler l’outil de versionning Git et apprenez à travailler en équipe avec les
                                        meilleurs outils et méthodologies AGILES
                                    </p>
                                    <p>
                                        <strong>Intégration continue (CI) - 5 jours </strong>
                                    </p>
                                    <p>
                                        Vous pourrez mettre en place les méthodes et les processus d’automatisation du
                                        <a href="/articles/quest-ce-quun-devops">DevOps</a>
                                        afin d’optimiser et de sécuriser le code source créé par l &#x27;équipe de
                                        développement.
                                    </p>
                                    <p>
                                        ‍<strong>Déploiement continu (CD) - 2 jours</strong>
                                    </p>
                                    <p>Durant cette formation DevOps, vous apprendrez automatiser la mise en production
                                        d’une application et créer différent environnement de déploiement. Vérifier la
                                        fiabilité de l’application en exécutant des tests de montée en charge </p>
                                </div>
                                <div class="flex-inline justify-start wrapp-on-phablet">
                                    <div class="stack-items-block">
                                        <div class="stack-logo-block">
                                            <img loading="lazy"
                                                src="https://assets-global.website-files.com/60506edfbf3c1c49a536edc5/6286344bb8a3bb772f6a84ce_Group.svg"
                                                alt="" class="cover-image" />
                                        </div>
                                        <div class="text-semi-bold">Git</div>
                                    </div>
                                    <div class="stack-items-block">
                                        <div class="stack-logo-block">
                                            <img loading="lazy"
                                                src="https://assets-global.website-files.com/60506edfbf3c1c49a536edc5/6286345db1771134b0d33149_Group%20684.svg"
                                                alt="" class="cover-image" />
                                        </div>
                                        <div class="text-semi-bold">Gitlab</div>
                                    </div>
                                    <div class="stack-items-block">
                                        <div class="stack-logo-block width-auto">
                                            <img loading="lazy"
                                                src="https://assets-global.website-files.com/60506edfbf3c1c49a536edc5/6286346d5abe5c4e5d8e883d_Group%20685.svg"
                                                alt="" class="cover-image large-cover" />
                                        </div>
                                        <div class="text-semi-bold">SonarCube</div>
                                    </div>
                                </div>
                            </div>
                            <div data-w-tab="infrastructure" class="program-tab-pane w-tab-pane w--tab-active">
                                <div class="mb-s1 w-richtext">
                                    <p>
                                        <strong>Conteneurisation - 4 jours</strong>
                                    </p>
                                    <p>
                                        Lors de cette formation <a
                                            href="/articles/comment-devenir-ingenieur-devops">ingénieur DevOps</a>
                                        , vous apprendrez à mettre en place des conteneurs par service pour rendre une
                                        application plus maintenable et sécurisée.
                                    </p>
                                    <p>
                                        <strong>Orchestration - 2 jours</strong>
                                    </p>
                                    <p>Vous apprendrez à superviser un groupe de service sur plusieurs serveurs afin de
                                        garantir la haute disponibilité et scalabilité des ressources </p>
                                    <p>
                                        <strong>Hosting &amp;Cloud - 7 jours</strong>
                                    </p>
                                    <p>
                                        <strong>‍</strong>
                                        Lors de ce module, vous apprendrez à utiliser les différents services cloud (AWS
                                        et Linode) liés au déploiement d’une application. Préparez les certifications
                                        AWS Cloud Practitioner et AWS Solutions Architect.
                                    </p>
                                    <p>
                                        ‍<strong>Monitoring - 2 jours</strong>
                                    </p>
                                    <p>Analysez et surveillez les données des services déployés en production avec un
                                        système d’alertes et de dashboard de monitoring avec Prometheus et Grafana.</p>
                                    <p>
                                        ‍<strong>Automatisation (iaC) - 3 jours </strong>
                                    </p>
                                    <p>Vous apprendrez à mettre en place un système automatique de création et de
                                        configuration de serveurs afin d &#x27;accélérer leur déploiement.</p>
                                </div>
                                <div class="flex-inline justify-start wrapp-on-phablet">
                                    <div class="stack-items-block">
                                        <div class="stack-logo-block">
                                            <img loading="lazy"
                                                src="https://assets-global.website-files.com/60506edfbf3c1c49a536edc5/62863521581601367ab5eab6_vertical-logo-monochromatic%201.svg"
                                                alt="" class="cover-image" />
                                        </div>
                                        <div class="text-semi-bold">Docker</div>
                                    </div>
                                    <div class="stack-items-block">
                                        <div class="stack-logo-block">
                                            <img loading="lazy"
                                                src="https://assets-global.website-files.com/60506edfbf3c1c49a536edc5/62863502e3fab3dd04dd7c0f_Kubernetes_logo_without_workmark%201.svg"
                                                alt="" class="cover-image" />
                                        </div>
                                        <div class="text-semi-bold">Kubernetes</div>
                                    </div>
                                    <div class="stack-items-block">
                                        <div class="stack-logo-block">
                                            <img loading="lazy"
                                                src="https://assets-global.website-files.com/60506edfbf3c1c49a536edc5/62863543c8d6ffe34ca31e2b_1200px-Amazon_Web_Services_Logo%203.svg"
                                                alt="" class="cover-image" />
                                        </div>
                                        <div class="text-semi-bold">AWS</div>
                                    </div>
                                    <div class="stack-items-block">
                                        <div class="stack-logo-block">
                                            <img loading="lazy"
                                                src="https://assets-global.website-files.com/60506edfbf3c1c49a536edc5/62863565d3e2e849deaeee7a_grafana-logo-15BA0AFA8A-seeklogo%201.svg"
                                                alt="" class="cover-image" />
                                        </div>
                                        <div class="text-semi-bold">Grafana</div>
                                    </div>
                                    <div class="stack-items-block">
                                        <div class="stack-logo-block">
                                            <img loading="lazy"
                                                src="https://assets-global.website-files.com/60506edfbf3c1c49a536edc5/6286357eca8f114f9b333b56_path4486.svg"
                                                alt="" class="cover-image" />
                                        </div>
                                        <div class="text-semi-bold">Prometheus</div>
                                    </div>
                                    <div class="stack-items-block">
                                        <div class="stack-logo-block">
                                            <img loading="lazy"
                                                src="https://assets-global.website-files.com/60506edfbf3c1c49a536edc5/62863592fe33e96625891aab_Group%20686.svg"
                                                alt="" class="cover-image" />
                                        </div>
                                        <div class="text-semi-bold">Ansible</div>
                                    </div>
                                </div>
                            </div>
                            <div data-w-tab="projet-final" class="program-tab-pane w-tab-pane">
                                <div class="mb-s1 w-richtext">
                                    <p>
                                        ‍<strong>Gestion de projet - 1 jour</strong>
                                    </p>
                                    <p>
                                        Afin de vous préparer au mieux au <a
                                            href="/articles/zoom-sur-le-metier-de-devops">métier DevOps</a>
                                        et à travailler dans les meilleures entreprises tech, vous apprendrez à
                                        organiser des sprints et prioriser vos développements. Vous maîtriserez les
                                        méthodes phares des équipes produits pour gérer efficacement la progression de
                                        vos projets. 
                                    </p>
                                    <p>
                                        <strong>Projet - 10 jours</strong>
                                    </p>
                                    <p>Vous allez travailler sur un projet de formation, un cas pratique inspiré d’un
                                        cas réel rencontré par les différents membres de notre board pédagogique. Vous
                                        devrez vous mettre dans la peau d’un expert DevOps en entreprise et réaliser une
                                        infrastructure informatique de A à Z en opérant les meilleurs choix possibles,
                                        que ce soit au niveau des outils, de la sécurité ou de l &#x27;organisation des
                                        process. </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="content-align-center mt-s2 hide-on-desktop">
                        <a href="#" class="hero-button purple-button pop-in-syllabus w-button">Voir le syllabus</a>
                    </div>
                </div>
            </div>
        </div>
        <div id="diplome" class="hero-section">
            <div class="wrapper-1600 big-purple-banner">
                <div class="col-60">
                    <h3 class="h2-style text-regular">
                        Titre RNCP 6 Administrateur Système DevOps<br />
                        <span class="text-semi-bold text-cherry-gradient">niveau Bac +3/4</span>
                    </h3>
                    <p>
                        Notre programme est une formation certifiante ! Passez le titre professionnel d’<a
                            href="https://www.lacapsule.academy/certification-professionnelle-asd"
                            target="_blank">Administrateur Système DevOps</a>
                        (titre professionnel de niveau 6 EUR), un équivalent Bac +3/4.<br />
                        ‍<br />
                        Un diplôme qui vient compléter votre parcours de formation pour rendre votre bootcamp DevOps
                        encore plus attractif !<br />
                    </p>
                </div>
                <div class="div-block-9">
                    <div class="rncp-content">
                        <div class="div-block-11">
                            <div class="rncp-percent">86</div>
                            <div class="rncp-text">%</div>
                        </div>
                        <div class="rncp-text">de réussite*</div>
                    </div>
                    <img src="https://assets-global.website-files.com/60506edfbf3c1c49a536edc5/615b3841e235db46d55a4545_ertification-score.svg"
                        loading="lazy" alt="" />
                    <div class="div-block-14">
                        <p></p>
                    </div>
                    <div class="div-block-14">
                        <p></p>
                        <p class="text-small">*En sept. 2023 - Tous Campus</p>
                    </div>
                </div>
            </div>
        </div>
        <div id="financement">
            <div class="hero-section bg-blue-gradient">
                <div class="wrapper-1600 content-align-center mb-s2">
                    <h3 class="h2-style text-white">
                        Financer sa formation<strong></strong>
                    </h3>
                </div>
                <div class="wrapper-1600 flex-inline space-between vertical-on-phablet mb-s3">
                    <div class="col-47 full-size-on-phablet phablet-margin-bottom-24">
                        <div class="h5-style text-white">
                            Que vous soyez demandeurs d &#x27;emploi, salarié ou auto-entrepreneur, il existe une
                            solution de financement adaptée à votre situation !<br />
                            <a href="mailto:mailto:alienor.roussel@lacapsule.academy" class="text-white"></a>
                            <br />
                        </div>
                        <p class="text-white mb-s3">
                            Pour en savoir plus, contactez-nous, nous répondrons à toutes vos questions sur les
                            financements : <br />
                            ‍<a href="mailto:mailto:hello@lacapsule.academy"
                                class="text-white">hello@lacapsule.academy</a>
                            <br />
                        </p>
                    </div>
                    <div class="col-45 mb-s2">
                        <div class="flex-inline-justify mb-s2 space-around-on-phablet">
                            <div class="very-small-white-banner phone-logo-size">
                                <img src="https://assets-global.website-files.com/60506edfbf3c1c49a536edc5/63f60128ee831d6e2bc0faac_Re%CC%81gion_I%CC%82le-de-France_(logo).svg.png"
                                    loading="lazy" width="116"
                                    sizes="(max-width: 767px) 18vw, (max-width: 1919px) 7vw, 116px" alt=""
                                    srcset="https://assets-global.website-files.com/60506edfbf3c1c49a536edc5/63f60128ee831d6e2bc0faac_Re%CC%81gion_I%CC%82le-de-France_(logo).svg-p-500.png 500w, https://assets-global.website-files.com/60506edfbf3c1c49a536edc5/63f60128ee831d6e2bc0faac_Re%CC%81gion_I%CC%82le-de-France_(logo).svg-p-800.png 800w, https://assets-global.website-files.com/60506edfbf3c1c49a536edc5/63f60128ee831d6e2bc0faac_Re%CC%81gion_I%CC%82le-de-France_(logo).svg-p-1080.png 1080w, https://assets-global.website-files.com/60506edfbf3c1c49a536edc5/63f60128ee831d6e2bc0faac_Re%CC%81gion_I%CC%82le-de-France_(logo).svg-p-1600.png 1600w, https://assets-global.website-files.com/60506edfbf3c1c49a536edc5/63f60128ee831d6e2bc0faac_Re%CC%81gion_I%CC%82le-de-France_(logo).svg-p-2000.png 2000w, https://assets-global.website-files.com/60506edfbf3c1c49a536edc5/63f60128ee831d6e2bc0faac_Re%CC%81gion_I%CC%82le-de-France_(logo).svg.png 2560w"
                                    class="image-9" />
                            </div>
                            <div class="very-small-white-banner phone-logo-size">
                                <img src="https://assets-global.website-files.com/60506edfbf3c1c49a536edc5/63f5fc7ec0774f485d081440_1280px-Logo_Po%CC%82le_Emploi_2008.svg%20(1).png"
                                    loading="lazy" width="49" sizes="49px" alt=""
                                    srcset="https://assets-global.website-files.com/60506edfbf3c1c49a536edc5/63f5fc7ec0774f485d081440_1280px-Logo_Po%CC%82le_Emploi_2008.svg%20(1)-p-500.png 500w, https://assets-global.website-files.com/60506edfbf3c1c49a536edc5/63f5fc7ec0774f485d081440_1280px-Logo_Po%CC%82le_Emploi_2008.svg%20(1)-p-800.png 800w, https://assets-global.website-files.com/60506edfbf3c1c49a536edc5/63f5fc7ec0774f485d081440_1280px-Logo_Po%CC%82le_Emploi_2008.svg%20(1)-p-1080.png 1080w, https://assets-global.website-files.com/60506edfbf3c1c49a536edc5/63f5fc7ec0774f485d081440_1280px-Logo_Po%CC%82le_Emploi_2008.svg%20(1).png 1280w"
                                    class="image-10" />
                            </div>
                            <div class="very-small-white-banner phone-logo-size">
                                <img src="https://assets-global.website-files.com/60506edfbf3c1c49a536edc5/63f600f2da820b035379c495_cpf.png"
                                    loading="lazy" width="61"
                                    sizes="(max-width: 479px) 17vw, (max-width: 767px) 13vw, (max-width: 991px) 7vw, 61px"
                                    alt=""
                                    srcset="https://assets-global.website-files.com/60506edfbf3c1c49a536edc5/63f600f2da820b035379c495_cpf-p-500.png 500w, https://assets-global.website-files.com/60506edfbf3c1c49a536edc5/63f600f2da820b035379c495_cpf-p-800.png 800w, https://assets-global.website-files.com/60506edfbf3c1c49a536edc5/63f600f2da820b035379c495_cpf-p-1080.png 1080w, https://assets-global.website-files.com/60506edfbf3c1c49a536edc5/63f600f2da820b035379c495_cpf-p-1600.png 1600w, https://assets-global.website-files.com/60506edfbf3c1c49a536edc5/63f600f2da820b035379c495_cpf-p-2000.png 2000w, https://assets-global.website-files.com/60506edfbf3c1c49a536edc5/63f600f2da820b035379c495_cpf.png 2213w" />
                            </div>
                        </div>
                        <div class="flex-inline-justify mb-s2">
                            <div class="very-small-white-banner phone-logo-size">
                                <img src="https://assets-global.website-files.com/60506edfbf3c1c49a536edc5/63f601a6e58f15d015ce09b4_Group%20443.png"
                                    loading="lazy" width="116"
                                    sizes="(max-width: 767px) 18vw, (max-width: 1919px) 7vw, 116px" alt=""
                                    srcset="https://assets-global.website-files.com/60506edfbf3c1c49a536edc5/63f601a6e58f15d015ce09b4_Group%20443-p-500.png 500w, https://assets-global.website-files.com/60506edfbf3c1c49a536edc5/63f601a6e58f15d015ce09b4_Group%20443-p-800.png 800w, https://assets-global.website-files.com/60506edfbf3c1c49a536edc5/63f601a6e58f15d015ce09b4_Group%20443-p-1080.png 1080w, https://assets-global.website-files.com/60506edfbf3c1c49a536edc5/63f601a6e58f15d015ce09b4_Group%20443.png 1356w" />
                            </div>
                            <div class="very-small-white-banner phone-logo-size">
                                <img src="https://assets-global.website-files.com/60506edfbf3c1c49a536edc5/63f60a3e8c4b9da6808338eb_opco.png"
                                    loading="lazy"
                                    sizes="(max-width: 479px) 192.39584350585938px, (max-width: 767px) 40vw, 166.39584350585938px"
                                    srcset="https://assets-global.website-files.com/60506edfbf3c1c49a536edc5/63f60a3e8c4b9da6808338eb_opco-p-500.png 500w, https://assets-global.website-files.com/60506edfbf3c1c49a536edc5/63f60a3e8c4b9da6808338eb_opco.png 500w"
                                    alt="" class="image-10" />
                            </div>
                            <div class="very-small-white-banner phone-logo-size">
                                <img src="https://assets-global.website-files.com/60506edfbf3c1c49a536edc5/63f5fdd0dd827fc8dbb5a16f_tp.png"
                                    loading="lazy" width="61" alt="" />
                            </div>
                        </div>
                        <div class="flex-inline-justify">
                            <div class="very-small-white-banner phone-logo-size">
                                <img src="https://assets-global.website-files.com/60506edfbf3c1c49a536edc5/6422c83a3ff567a9b0d871dd_download.jpeg"
                                    loading="lazy" width="60" alt="" />
                            </div>
                            <div class="very-small-white-banner phone-logo-size">
                                <img src="https://assets-global.website-files.com/60506edfbf3c1c49a536edc5/6422fc5e14a17ac2efabf72d_faf.png"
                                    loading="lazy" width="68"
                                    sizes="(max-width: 479px) 17vw, (max-width: 767px) 14vw, (max-width: 1919px) 7vw, 68px"
                                    alt=""
                                    srcset="https://assets-global.website-files.com/60506edfbf3c1c49a536edc5/6422fc5e14a17ac2efabf72d_faf-p-500.png 500w, https://assets-global.website-files.com/60506edfbf3c1c49a536edc5/6422fc5e14a17ac2efabf72d_faf-p-800.png 800w, https://assets-global.website-files.com/60506edfbf3c1c49a536edc5/6422fc5e14a17ac2efabf72d_faf.png 1024w" />
                            </div>
                            <div class="very-small-white-banner phone-logo-size">
                                <img src="https://assets-global.website-files.com/60506edfbf3c1c49a536edc5/63f6177ba4aa7d55c4210f17_agefiph.png"
                                    loading="lazy" width="60"
                                    sizes="(max-width: 479px) 15vw, (max-width: 767px) 13vw, (max-width: 991px) 6vw, 60px"
                                    alt=""
                                    srcset="https://assets-global.website-files.com/60506edfbf3c1c49a536edc5/63f6177ba4aa7d55c4210f17_agefiph-p-500.png 500w, https://assets-global.website-files.com/60506edfbf3c1c49a536edc5/63f6177ba4aa7d55c4210f17_agefiph-p-800.png 800w, https://assets-global.website-files.com/60506edfbf3c1c49a536edc5/63f6177ba4aa7d55c4210f17_agefiph.png 1160w"
                                    class="image-12" />
                            </div>
                        </div>
                    </div>
                </div>
                <a href="/financement" class="hero-button white-button flex-vertical-centered w-button">Découvrir tous
                    les financements</a>
            </div>
        </div>
        <div id="admission">
            <div id="processus" class="hero-section bg-color-grey-100 hide-on-desktop">
                <div class="wrapper-1600 content-align-center">
                    <h2>Les étapes du processus</h2>
                </div>
                <div class="wrapper-920 content-align-center mb-s3">
                    <div>Notre processus d &#x27;admission nous permet de nous assurer que vous avez ce qu &#x27;il faut
                        pour vous épanouir dans l &#x27;environnement d &#x27;apprentissage exigeant que nous offrons à
                        La Capsule.</div>
                </div>
                <div class="wrapper-1600">
                    <div class="wrapper-920">
                        <div class="typical-day-timeline">
                            <div class="typical-day-hour">
                                <img src="https://assets-global.website-files.com/60506edfbf3c1c49a536edc5/642aed1b724686203e7d9e49_noun-send-3612585-F94A56.png"
                                    loading="lazy" sizes="(max-width: 479px) 16vw, (max-width: 767px) 24px, 100vw"
                                    srcset="https://assets-global.website-files.com/60506edfbf3c1c49a536edc5/642aed1b724686203e7d9e49_noun-send-3612585-F94A56-p-500.png 500w, https://assets-global.website-files.com/60506edfbf3c1c49a536edc5/642aed1b724686203e7d9e49_noun-send-3612585-F94A56-p-800.png 800w, https://assets-global.website-files.com/60506edfbf3c1c49a536edc5/642aed1b724686203e7d9e49_noun-send-3612585-F94A56-p-1080.png 1080w, https://assets-global.website-files.com/60506edfbf3c1c49a536edc5/642aed1b724686203e7d9e49_noun-send-3612585-F94A56.png 1200w"
                                    alt="" class="timeline-check" />
                            </div>
                        </div>
                        <div class="typical-day-content">
                            <div>
                                <div class="h4-style">Candidature</div>
                            </div>
                        </div>
                    </div>
                    <div class="wrapper-920">
                        <div class="typical-day-timeline">
                            <div class="typical-day-hour">
                                <div class="h3-style text-gradient-cherry">1</div>
                            </div>
                        </div>
                        <div class="typical-day-content">
                            <div>
                                <div class="h4-style margin-bottom-8">Test de logique</div>
                                <p class="text-small no-margin">40 min, en ligne, pour valider votre aptitude à suivre
                                    notre Bootcamp</p>
                            </div>
                        </div>
                    </div>
                    <div class="wrapper-920">
                        <div class="typical-day-timeline">
                            <div class="typical-day-hour">
                                <div class="h3-style text-gradient-cherry">2</div>
                            </div>
                        </div>
                        <div class="typical-day-content">
                            <div>
                                <div class="h4-style margin-bottom-8">Questions &amp;Réponses</div>
                                <p class="text-small no-margin">Posez toutes vos questions sur La Capsule !</p>
                            </div>
                        </div>
                    </div>
                    <div class="wrapper-920">
                        <div class="typical-day-timeline">
                            <div class="typical-day-hour">
                                <div class="h3-style text-gradient-cherry">3</div>
                            </div>
                        </div>
                        <div class="typical-day-content">
                            <div>
                                <div class="h4-style margin-bottom-8">Entretien de motivation</div>
                                <p class="text-small no-margin">Un entretien individuel pour en savoir plus sur vos
                                    projets</p>
                            </div>
                        </div>
                    </div>
                    <div class="wrapper-920 mb-s3">
                        <div class="typical-day-timeline">
                            <div class="typical-day-hour">
                                <img src="https://assets-global.website-files.com/60506edfbf3c1c49a536edc5/607c31608d79bb4469f61baf_icon-check-cherry-400.svg"
                                    loading="lazy" alt="" class="timeline-check" />
                            </div>
                        </div>
                        <div class="typical-day-content">
                            <div>
                                <div class="h4-style">Admission</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="lottie-anim">
                    <div class="lottie-wave-big-size" data-w-id="03511527-a8ca-c0d4-88f3-105335ff4a1e"
                        data-animation-type="lottie"
                        data-src="https://assets-global.website-files.com/60506edfbf3c1c49a536edc5/60ad2963221f39a76002453b_anim-fond-bleu.json"
                        data-loop="1" data-direction="1" data-autoplay="1" data-is-ix2-target="0" data-renderer="svg"
                        data-default-duration="21" data-duration="45"></div>
                </div>
            </div>
            <div id="processus" class="hero-section bg-color-grey-100 hide-on-tablet">
                <div class="wrapper-1600 content-align-center">
                    <h2>Les étapes du processus d &#x27;admission</h2>
                </div>
                <div class="wrapper-920 content-align-center mb-s3">
                    <div>Notre processus d &#x27;admission nous permet de nous assurer que vous avez ce qu &#x27;il faut
                        pour vous épanouir dans l &#x27;environnement d &#x27;apprentissage exigeant que nous offrons à
                        La Capsule.</div>
                </div>
                <div class="wrapper-1600 flex-inline mb-s3 mt-s1">
                    <div class="wrapper-250">
                        <div class="typical-day-timeline-horizontal mb-s3">
                            <div class="timeline-divider-horizontal start"></div>
                            <div class="typical-day-hour">
                                <img src="https://assets-global.website-files.com/60506edfbf3c1c49a536edc5/607c31608d79bb4469f61baf_icon-check-cherry-400.svg"
                                    loading="lazy" alt="" class="timeline-check" />
                            </div>
                        </div>
                        <div class="timeline-horizontal-content">
                            <div class="h6-style margin-bottom-4">Candidature</div>
                            <div>L &#x27;équipe d &#x27;admission vous contacte dans la journée</div>
                        </div>
                    </div>
                    <div class="wrapper-250">
                        <div class="typical-day-timeline-horizontal mb-s3">
                            <div class="timeline-divider-horizontal"></div>
                            <div class="typical-day-hour">
                                <img src="https://assets-global.website-files.com/60506edfbf3c1c49a536edc5/607c31608d79bb4469f61baf_icon-check-cherry-400.svg"
                                    loading="lazy" alt="" class="timeline-check" />
                            </div>
                        </div>
                        <div class="timeline-horizontal-content no-margin no-padding">
                            <div class="h6-style margin-bottom-4">Test de logique</div>
                            <div>40 min, en ligne, pour valider votre aptitude à suivre notre Bootcamp</div>
                        </div>
                    </div>
                    <div class="wrapper-250">
                        <div class="typical-day-timeline-horizontal mb-s3">
                            <div class="timeline-divider-horizontal"></div>
                            <div class="typical-day-hour">
                                <img src="https://assets-global.website-files.com/60506edfbf3c1c49a536edc5/607c31608d79bb4469f61baf_icon-check-cherry-400.svg"
                                    loading="lazy" alt="" class="timeline-check" />
                            </div>
                        </div>
                        <div class="timeline-horizontal-content no-margin no-padding">
                            <div class="h6-style margin-bottom-4">Question &amp;Réponses</div>
                            <div>
                                Une session <br />
                                d &#x27;information en ligne <br />sur nos programmes
                            </div>
                        </div>
                    </div>
                    <div class="wrapper-250">
                        <div class="typical-day-timeline-horizontal mb-s3">
                            <div class="timeline-divider-horizontal"></div>
                            <div class="typical-day-hour">
                                <img src="https://assets-global.website-files.com/60506edfbf3c1c49a536edc5/607c31608d79bb4469f61baf_icon-check-cherry-400.svg"
                                    loading="lazy" alt="" class="timeline-check" />
                            </div>
                        </div>
                        <div class="timeline-horizontal-content no-margin no-padding">
                            <div class="h6-style margin-bottom-4">Entretien individuel</div>
                            <div>
                                Un entretien de <br />motivation pour en savoir plus sur vos projets
                            </div>
                        </div>
                    </div>
                    <div class="wrapper-250">
                        <div class="typical-day-timeline-horizontal mb-s3">
                            <div class="timeline-divider-horizontal end"></div>
                            <div class="typical-day-hour">
                                <img src="https://assets-global.website-files.com/60506edfbf3c1c49a536edc5/607c31608d79bb4469f61baf_icon-check-cherry-400.svg"
                                    loading="lazy" alt="" class="timeline-check" />
                            </div>
                        </div>
                        <div class="timeline-horizontal-content">
                            <div class="h6-style margin-bottom-4">Admission</div>
                            <div>Lancez votre carrière dans la Tech !</div>
                        </div>
                    </div>
                </div>
                <div class="lottie-anim">
                    <div class="lottie-wave-big-size" data-w-id="61bb9c02-6664-a915-b5c3-8ac2a94bcf85"
                        data-animation-type="lottie"
                        data-src="https://assets-global.website-files.com/60506edfbf3c1c49a536edc5/60ad2963221f39a76002453b_anim-fond-bleu.json"
                        data-loop="1" data-direction="1" data-autoplay="1" data-is-ix2-target="0" data-renderer="svg"
                        data-default-duration="21" data-duration="45"></div>
                </div>
                <a href="/postuler" class="hero-button mt-s2 w-button">Postuler</a>
            </div>
        </div>
        <div id="careercenter" class="hero-section bg-color-white">
            <div class="wrapper-1600 content-align-center mb-s3 left-aligned flex-vertical no-padding">
                <div class="w-embed">
                    <h3 class="h2-style">
                        Quels débouchés <span class="text-regular">après la formation DevOps ?
                        </span>
                    </h3>
                </div>
                <div class="margin-top-4">Notre cursus de formation DevOps vous permettra de vous reconvertir sur de
                    nombreux métiers. </div>
                <div class="job-cards">
                    <div data-w-id="b5de77a6-8d85-00fb-814b-5312af43e23b"  class="job-card">
                        <div class="job-img job-img-1">
                            <div class="job-card-title h3-style text-white">DevOps</div>
                        </div>
                        <div class="job-card-info">L &#x27;expert est l’interface entre les équipes de développement et
                            l &#x27;opérationnel. Il permet de fluidifier le déploiement des applications et les
                            processus de développement.</div>
                    </div>
                    <div data-w-id="6329b974-8100-12ca-01b2-2321c9dd3d11"  class="job-card">
                        <div class="job-img job-img-2">
                            <div class="job-card-title h3-style text-white">Site Reliability Engineer (SRE)</div>
                        </div>
                        <div class="job-card-info">Son rôle est de s’assurer de la parfaite  disponibilité de
                            l’application à tous les niveaux. Latence - Trafic - Erreur et Saturation sont les 4 ennemis
                            du SRE.</div>
                    </div>
                </div>
            </div>
            <div class="flex-inline show-more-button-wrapper">
                <div data-w-id="aaa24458-888b-0aad-84c5-0e2ab64cecda" class="show-more-button">
                    <div class="inline-block bold-text">Voir tous les débouchés</div>
                </div>
            </div>
            <div
                class="wrapper-1600 content-align-center mb-s3 left-aligned flex-vertical show-more-collection no-padding">
                <div class="job-cards">
                    <div class="job-card no-effect">
                        <div class="job-img job-img-3">
                            <div class="job-card-title h3-style text-white">Cloud Engineer</div>
                        </div>
                        <div class="job-card-info">C’est un spécialiste du Cloud et travaille notamment avec des
                            technologies comme AWS Google Cloud, Azure…</div>
                    </div>
                    <div class="job-card no-effect">
                        <div class="job-img job-img-4">
                            <div class="job-card-title h3-style text-white">DevSecOps</div>
                        </div>
                        <div class="job-card-info">C’est un DevOps dont le rôle est plus axé sur la sécurité et l
                            &#x27;architecture. Il travaille sur l’ensemble de la chaîne de développement et de mise en
                            production avec pour objectif d’éviter toute coupure.</div>
                    </div>
                </div>
                <div class="job-cards flex-inline-centered">
                    <div class="job-card no-effect">
                        <div class="job-img job-img-5">
                            <div class="job-card-title h3-style text-white">System Engineer &amp;Network Engineer</div>
                        </div>
                        <div class="job-card-info">Ce sont des opérationnels qui travaillent sur les systèmes (serveurs
                            ou machines virtuelle) et le réseau - accessibilité Internet et la mise en place de réseaux
                            publics ou privés (VPN - Proxy - Firewall).</div>
                    </div>
                </div>
            </div>
        </div>
        <div id="faq">
            <div class="hero-section w-condition-invisible">
                <div class="wrapper-1600 content-align-center">
                    <div class="w-condition-invisible w-embed">
                        <h3 class="h2-style">
                            <span class="text-regular">Vos questions sur</span>
                            <br />
                            la formation DevOps <span class="text-gradient-cherry"></span>
                        </h3>
                    </div>
                </div>
                <div class="wrapper-1600">
                    <div class="w-dyn-list">
                        <div class="empty-state-8 w-dyn-empty">
                            <div>No items found.</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
            {/* scrolltop-start */}
            <ScrollToTop
                scrollClassName="scrollup orange-color"
            />
            {/* scrolltop-end */}

        </React.Fragment>
    )
}

export default CourseDetailsMain;