import React, { useState, useEffect } from 'react';
import CourseSingle from './CourseSingle';
import axios from 'axios';
import { useLocation } from 'react-router-dom';

const CoursePart = (props) => {
    const [courses, setCourses] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedFilters, setSelectedFilters] = useState({
        thematique: [],
        plateforme: [],
        duree: []
    });

    const location = useLocation();

    const listClassAdd = () => {
        document.getElementById("rs-popular-course").classList.add('list-view');
    };

    useEffect(() => {
        const apiUrl = process.env.REACT_APP_API_URL+'/formations/formations';

        axios.get(apiUrl)
            .then(response => {
                setCourses(response.data);
            })
            .catch(error => {
                console.error('Error fetching courses:', error);
            });

        const pathSegments = location.pathname.split('/'); // Split the URL path by '/'
        const defaultFilters = { thematique: [], plateforme: [], duree: [] };

        if (pathSegments.includes("Cloud")) {
            defaultFilters.thematique.push('Cloud & Infrastructure');
        }
        if (pathSegments.includes("Data")) {
            defaultFilters.thematique.push('Data & IA');
        }
        if (pathSegments.includes("DevOps")) {
            defaultFilters.thematique.push('DevOps & DevSecOps');
        }
        if (pathSegments.includes("Cyber")) {
            defaultFilters.thematique.push('Cyber Securité');
        }

        if (pathSegments.includes("Azure")) {
            defaultFilters.plateforme.push('Azure (Microsoft)');
        }
        if (pathSegments.includes("Gcp")) {
            defaultFilters.plateforme.push('GCP (Google)');
        }
        if (pathSegments.includes("Kubernetes")) {
            defaultFilters.plateforme.push('Kubernetes (Linux foundation)');
        }
        setSelectedFilters(defaultFilters);
    }, [location.pathname]); // Run this effect whenever the location pathname changes

    const handleChange = (event) => {
        setSearchQuery(event.target.value);
        setSelectedFilters({ thematique: [], plateforme: [], duree: [] });
    };

    const countCoursesByFilter = (filter, value) => {
        return courses.filter(course => course[filter].includes(value)).length;
    };

    const handleCheckboxChange = (event) => {
        const { name, value, checked } = event.target;
        setSelectedFilters(prevState => ({
            ...prevState,
            [name]: checked
                ? [...prevState[name], value]
                : prevState[name].filter(val => val !== value)
        }));
        setSearchQuery('');
    };

    const filteredCourses = courses.filter(course => {
        const plateformeMatch = selectedFilters.plateforme.length === 0 || selectedFilters.plateforme.includes(course.plateforme);
        const thematiqueMatch = selectedFilters.thematique.length === 0 || selectedFilters.thematique.includes(course.thematique);
        const dureeMatch = selectedFilters.duree.length === 0 || selectedFilters.duree.includes(course.durée);
        const queryMatch = searchQuery.trim() === '' ||
            course.titre.toLowerCase().includes(searchQuery.toLowerCase()) ||
            course.plateforme.toLowerCase().includes(searchQuery.toLowerCase()) ||
            course.thematique.toLowerCase().includes(searchQuery.toLowerCase());
        return queryMatch && plateformeMatch && thematiqueMatch && dureeMatch;
    });

    return (
        <div id="rs-popular-course" className="rs-popular-courses style1 course-view-style orange-style rs-inner-blog white-bg pt-100 pb-100 md-pt-70 md-pb-80 list-view">
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 col-md-12 order-last">
                        <React.Fragment>
                            <div className="widget-area">
                                <div className="search-widget mb-50">
                                    <h3 className="widget-title">Trouver ma formation</h3>
                                    <div className="search-wrap">
                                        <input type="search" placeholder="Search..." name="s" className="search-input" value={searchQuery} onChange={handleChange} />
                                        <button type="submit" value="Search"><i className="flaticon-search"></i></button>
                                    </div>
                                </div>
                                <div className="widget-archives mb-50">
                                    <h3 className="widget-title">Filtrer avec</h3>
                                    <div className="filter-widget">
                                        <div className="filter-form">
                                            <form method="post" action="mailer.php">
                                                <div className="single-filter mb-30">
                                                    <h5>Thématiques</h5>
                                                    {['Cloud & Infrastructure', 'Data & IA', 'DevOps & DevSecOps', 'Cyber Securité'].map((thematique, index) => (
                                                        <div className="check-box form-group mb-0" key={index}>
                                                            <input
                                                                type="checkbox"
                                                                name="thematique"
                                                                id={`thematique${index}`}
                                                                value={thematique}
                                                                checked={selectedFilters.thematique.includes(thematique)}
                                                                onChange={handleCheckboxChange}
                                                            />
                                                            <label htmlFor={`thematique${index}`}>{thematique} ({countCoursesByFilter('thematique', thematique)})</label>
                                                        </div>
                                                    ))}
                                                </div>

                                                <div className="single-filter mb-30">
                                                    <h5>Plateformes</h5>
                                                    {['Azure (Microsoft)', 'GCP (Google)', 'Kubernetes (Linux foundation)'].map((plateforme, index) => (
                                                        <div className="check-box form-group mb-0" key={index}>
                                                            <input
                                                                type="checkbox"
                                                                name="plateforme"
                                                                id={`plateforme${index}`}
                                                                value={plateforme}
                                                                checked={selectedFilters.plateforme.includes(plateforme)}
                                                                onChange={handleCheckboxChange}
                                                            />
                                                            <label htmlFor={`plateforme${index}`}>{plateforme} ({countCoursesByFilter('plateforme', plateforme)})</label>
                                                        </div>
                                                    ))}
                                                </div>

                                                <div className="single-filter mb-30">
                                                    <h5>Durée</h5>
                                                    {['1 jour / 4h', '1 jour / 7h', '3 jours / 21h', '3 jours / 28h'].map((duree, index) => (
                                                        <div className="check-box form-group mb-0" key={index}>
                                                            <input
                                                                type="checkbox"
                                                                name="duree"
                                                                id={`duree${index}`}
                                                                value={duree}
                                                                checked={selectedFilters.duree.includes(duree)}
                                                                onChange={handleCheckboxChange}
                                                            />
                                                            <label htmlFor={`duree${index}`}>{duree} ({countCoursesByFilter('durée', duree)})</label>
                                                        </div>
                                                    ))}
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </React.Fragment>
                    </div>
                    <div className="col-lg-8 pr-50 md-pr-14">
                        <div className="course-search-part">
                            <div className="course-view-part">
                                <div className="view-icons">
                                    {/* <button onClick={listClassRemove} className="view-grid mr-10"><i className="fa fa-th-large"></i></button> */}
                                    <button onClick={listClassAdd} className="view-list"><i className="fa fa-list-ul"></i></button>
                                </div>
                                <div className="view-text">{filteredCourses.length} cours disponibles</div>
                            </div>
                        </div>

                        <div className="course-part clearfix">
                            {filteredCourses.map((course, index) => (
                                <CourseSingle
                                    key={index}
                                    courseClass={`courses-item${index % 2 === 0 ? '' : ' right'}`} // Alternate classes for left and right alignment
                                    courseTitre={course.titre}
                                    coursePrix={course.prix}
                                    courseThem={course.thematique}
                                    courseDetails={course.details}
                                    coursePlateforme={course.plateforme}
                                    courseDuree={course.durée}
                                    courseImg={course.image}
                                    courseDesc={course.description}
                                />
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CoursePart;
