import React from 'react';
import { Link, useLocation } from 'react-router-dom';

const MenuItems = (props) => {
    const { parentMenu } = props;
    const location = useLocation();

    return (
        <React.Fragment>
            <li className={parentMenu === 'Bootcamps' ? 'rs-mega-menu menu-item-has-children current-menu-item' : 'rs-mega-menu menu-item-has-children'}>
                <Link to="#">Bootcamps</Link>
                <ul className="mega-menu two-column">
                    <li className="mega-menu-container">
                        <div className="single-megamenu ">
                            <ul className="sub-menu">
                                <li>
                                    <Link to="/Bootcamps/Kubernetes" className={location.pathname === "/Bootcamps/Kubernetes" ? "active-menu" : ""}>
                                        Orchestration avec Kubernetes
                                        <div className="caption">Apprenez le Kubernetes en 4 semaines</div>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                        <div className="single-megamenu">
                            <ul className="sub-menu">
                                <li>
                                    <Link to="/Bootcamps/Cloud" className={location.pathname === "/Bootcamps/Cloud" ? "active-menu" : ""}>
                                        Ingénieur Cloud & DevOps
                                        <div className="caption">Apprenez le DevOps en 10 semaines</div>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </li>
                </ul>
            </li>
            <li className={parentMenu === 'Training' ? 'rs-mega-menu menu-item-has-children current-menu-item' : 'rs-mega-menu menu-item-has-children'}>
                <Link to="#">Formations</Link>
                <ul className="mega-menu two-column">
                    <li className="mega-menu-container">
                        <div className="single-megamenu ">
                            <ul className="sub-menu">
                                <li>
                                    <div className="menu-group-title"><strong>Par thématique</strong></div>
                                </li>
                                <li>
                                    <Link to="/Training/Cloud" className={location.pathname === "/Training/Cloud" ? "active-menu" : ""}>Cloud & Infrastructure</Link>
                                </li>
                                <li>
                                    <Link to="/Training/Data" className={location.pathname === "/Training/Data" ? "active-menu" : ""}>Data & IA</Link>
                                </li>
                                <li>
                                    <Link to="/Training/DevOps" className={location.pathname === "/Training/DevOps" ? "active-menu" : ""}>DevOps & DevSecOps</Link>
                                </li>
                                <li>
                                    <Link to="/Training/Cyber" className={location.pathname === "/Training/Cyber" ? "active-menu" : ""}>Cyber Securité</Link>
                                </li>
                            </ul>
                        </div>
                        <div className="single-megamenu">
                            <ul className="sub-menu">
                                <li>
                                    <div className="menu-group-title"><strong>Par plateforme</strong></div>
                                </li>
                                <li>
                                    <Link to="/Training/Azure" className={location.pathname === "/Training/Azure" ? "active-menu" : ""}>Azure ( Microsoft ) </Link>
                                </li>
                                <li>
                                    <Link to="/Training/Gcp" className={location.pathname === "/Training/Gcp" ? "active-menu" : ""}>GCP ( Google ) </Link>
                                </li>
                                <li>
                                    <Link to="/Training/Kubernetes" className={location.pathname === "/Training/Kubernetes" ? "active-menu" : ""}>Kubernetes ( Linux foundation ) </Link>
                                </li>
                            </ul>
                        </div>
                    </li>
                </ul>
            </li>
            <li className={parentMenu === 'Certifications' ? 'menu-item-has-children current-menu-item' : 'menu-item-has-children'}>
                <Link to="#">Certifications</Link>
                <ul className="sub-menu">
                    <li>
                        <div className="menu-group-title"><strong>Par Editeur</strong></div>
                    </li>
                    <li>
                        <Link to="/Certifications/Microsoft" className={location.pathname === "/Certifications/Microsoft" ? "active-menu" : ""}>Microsoft ( Azure ) </Link>
                    </li>
                    <li>
                        <Link to="/Certifications/Google" className={location.pathname === "/Certifications/Google" ? "active-menu" : ""}>Google ( GCP ) </Link>
                    </li>
                    <li>
                        <Link to="/Certifications/Linux" className={location.pathname === "/Certifications/Linux" ? "active-menu" : ""}>Linux foundation ( Kubernetes ) </Link>
                    </li>
                </ul>
            </li>
            <li className={parentMenu === 'blog' ? 'menu-item-has-children current-menu-item' : 'menu-item-has-children'}>
                <Link to="#">Blog</Link>
                <ul className="sub-menu">
                    <li>
                        <Link to="/blog/Ressources" className={location.pathname === "/blog/Ressources" ? "active-menu" : ""}>Ressources techniques</Link>
                    </li>
                    <li>
                        <Link to="/blog/events" className={location.pathname === "/blog/events" ? "active-menu" : ""}>Evènements</Link>
                    </li>
                </ul>
            </li>
            <li className={parentMenu === 'about' ? 'rs-mega-menu menu-item-has-children current-menu-item' : 'rs-mega-menu menu-item-has-children'}>
                <Link to="#">Nous connaitre</Link>
                <ul className="mega-menu two-column">
                    <li className="mega-menu-container">
                        <div className="single-megamenu ">
                            <ul className="sub-menu">
                                <li>
                                    <div className="menu-group-title"><strong>CloudiaSys Academy</strong></div>
                                </li>
                                <li>
                                    <Link to="/about/nousConnaitre" className={location.pathname === "/about/nousConnaitre" ? "active-menu" : ""}>Qui Sommes-nous</Link>
                                </li>
                                <li>
                                    <Link to="/about/notreMethode" className={location.pathname === "/about/notreMethode" ? "active-menu" : ""}>Notre Méthode</Link>
                                </li>
                                <li>
                                    <Link to="/about/partenaires" className={location.pathname === "/about/partenaires" ? "active-menu" : ""}>Nos Partenaires</Link>
                                </li>
                            </ul>
                        </div>
                        <div className="single-megamenu">
                            <ul className="sub-menu">
                                <li>
                                    <div className="menu-group-title"><strong>Finencement</strong></div>
                                </li>
                                <li>
                                    <Link to="/about/CPF" className={location.pathname === "/about/CPF" ? "active-menu" : ""}>CPF</Link>
                                </li>
                                <li>
                                    <Link to="/about/OPCP" className={location.pathname === "/about/OPCP" ? "active-menu" : ""}>OPCP</Link>
                                </li>
                                <li>
                                    <Link to="/about/POE" className={location.pathname === "/about/POE" ? "active-menu" : ""}>POE</Link>
                                </li>
                            </ul>
                        </div>
                    </li>
                </ul>
            </li>
            <li className={parentMenu === 'contact' ? 'menu-item-has-children current-menu-item' : 'menu-item-has-children'}>
                <Link to="#">
                    Contact
                </Link>
                <ul className="sub-menu">
                    <li>
                        <Link to="/contact" className={location.pathname === "/contact" ? "active-menu" : ""}>Nous contacter</Link>
                    </li>
                </ul>
            </li>
        </React.Fragment>
    );
}

export default MenuItems;