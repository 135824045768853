import React from 'react';
import { Link } from 'react-router-dom';
import img from '../../../assets/img/cloud-based-elearning.jpg'
const SiteBreadcrumb = (props) => {
	const { breadcrumbsClass, innerClass, titleClass, pageTitle, parentCategory, pageCategory, pageName, breadcrumbsImg } = props;

	return (

		<div class="big-wrapper">
			<div class="bootcamp-section main-header-comp bgcolor-midnight-gradient">
				<div class="wrapper-1600 flex-inline justify-start position-relative wrapp-on-phablet">
					<div class="col-60">
						<div class="mb-s2">
							<p class="h2-style text-white no-margin">Formation DevOps Engineer</p>
							<div data-hover="false" data-delay="200" class="text-dropdown w-condition-invisible w-dropdown">
								<div class="text-dropdown-toogle w-dropdown-toggle">
									<div class="h2-style text-gradient-cherry w-dyn-bind-empty"></div>
								</div>
								<nav class="text-dropdown-list w-dropdown-list">
									<div>
										<div class="w-dyn-list">
											<div role="list" class="w-dyn-items">
												<div role="listitem" class="ucfirst w-dyn-item">
													<a href="/program/devops-full-time-remote" class="text-dropdown-link">à distance</a>
												</div>
												<div role="listitem" class="ucfirst w-dyn-item">
													<a href="/program/devops-full-time-paris" class="text-dropdown-link">Paris</a>
												</div>
												<div role="listitem" class="ucfirst w-dyn-item">
													<a href="/program/devops-full-time" aria-current="page" class="text-dropdown-link w-dyn-bind-empty w--current"></a>
												</div>
											</div>
										</div>
									</div>
								</nav>
							</div>
						</div>
						<div>
							<div class="margin-bottom-32 mt-s1 w-richtext">
								<div class="w-embed">
									<p class="h5-style text-regular text-white">
										Le bootcamp	DevOps qui vous donnera toutes les clés pour changer de vie en seulement 10 semaines et obtenir les compétences et le réseau d’un DevOps.
									</p>
								</div>
							</div>
						</div>
						<div className="btn-part">
							<a href="#" className=" readon2 orange-transparent-button">Télécharger le programme</a>
						</div>

					</div>
					<div class="bootcamp-header-image">
						<img src={img} loading="lazy" alt="" sizes="(max-width: 767px) 88vw, (max-width: 991px) 33vw, (max-width: 1919px) 28vw, 450px" class="cover-image" />
					</div>
				</div>
				<div class="lottie-anim">
					<div class="lottie-sprinkle-scalling full-size" data-w-id="96b37ed1-de7b-161b-48c9-d4ff8f133302" data-animation-type="lottie" data-src="https://assets-global.website-files.com/60506edfbf3c1c49a536edc5/60ad2963221f39a76002453b_anim-fond-bleu.json" data-loop="1" data-direction="-1" data-autoplay="1" data-is-ix2-target="0" data-renderer="svg" data-default-duration="21" data-duration="45"></div>
				</div>
			</div>
		</div>
	);
}

export default SiteBreadcrumb;




