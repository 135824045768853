import React from 'react';
import { Editor } from '@tinymce/tinymce-react';

class TinyEditor extends React.Component {
    constructor(props) {
        super(props);
        this.editorRef = React.createRef();
    }

    render() {
        const { value, height, menubar, plugins, toolbar, handleEditorChange } = this.props;

        return (
            <>
                <Editor
                    value={value}
                    onInit={(evt, editor) => { this.editorRef.current = editor }}
                    init={{
                        license_key: 'gpl',
                        height: height || 500,
                        menubar: menubar || true,
                        plugins: [
                            'advlist', 'autolink', 'lists', 'link', 'image', 'charmap',
                            'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                            'insertdatetime', 'media', 'table', 'preview', 'wordcount'
                        ],
                        contextmenu: "copy paste | link image inserttable",
                        toolbar: toolbar || 'undo redo | formatselect | bold italic underline forecolor backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat code | help | image media emoticons | fullscreen',
                        toolbar_mode: 'sliding',
                        file_picker_callback: (cb, value, meta) => {
                            const input = document.createElement("input");
                            input.setAttribute("type", "file");
                            input.setAttribute("accept", "image/*");
                            input.onchange = () => {
                                const file = input.files[0];

                                const reader = new FileReader();
                                reader.onload = () => {
                                    const id = "blobid" + new Date().getTime();
                                    const blobCache = this.editorRef.current.editorUpload.blobCache;
                                    const base64 = reader.result.split(",")[1];
                                    const blobInfo = blobCache.create(id, file, base64);
                                    blobCache.add(blobInfo);

                                    /* call the callback and populate the Title field with the file name */
                                    cb(blobInfo.blobUri(), { title: file.name });
                                };
                                reader.readAsDataURL(file);
                            };
                            input.click();
                        },
                        paste_data_images: true,
                        content_style: "body { z-index: 0; }",
                        setup: (editor) => {
                            editor.on('init', () => {
                                // Hide the promotion button with class 'tox-promotion'
                                const promotionButton = editor.iframeElement.closest('.tox-editor-container').querySelector('.tox-promotion');
                                if (promotionButton) {
                                    promotionButton.style.display = 'none';
                                }

                                const branding = editor.editorContainer.querySelector('.tox-statusbar .tox-statusbar__branding');
                                if (branding) {
                                    branding.style.display = 'none';
                                }

                                // Adjust other settings or modifications as needed
                                const header = editor.iframeElement.closest('.tox-editor-container').querySelector('.tox-editor-header');
                                if (header) {
                                    header.style.zIndex = '0';  // Adjust z-index value as needed
                                }
                            });
                        }
                    }}
                    onEditorChange={handleEditorChange}
                />
            </>
        );
    }
}

export default TinyEditor;
