import React from 'react';
import { Editor } from '@tinymce/tinymce-react';

class DisplayEditor extends React.Component {
    render() {
        const { value } = this.props;

        return (
            <>
                <div className="custom-tinymce">
                    <Editor
                        value={value}
                        init={{
                            license_key: 'gpl',
                            menubar: false,
                            toolbar: false,
                            readonly: true,
                            branding: false,
                            plugins: 'autoresize',
                            autoresize_bottom_margin: 0,
                            autoresize_overflow_padding: 0,
                            autoresize_max_height: 800,
                            statusbar: false,
                            setup: function (editor) {
                                editor.on('init', function () {
                                    const contentArea = editor.getContentAreaContainer().querySelector('iframe');
                                    if (contentArea) {
                                        contentArea.contentDocument.documentElement.style.cursor = 'default'; // Set cursor to default style
                                        contentArea.style.border = 'none'; // Remove iframe border
                                    }
                                });
                            }
                        }}
                        className='display-editor'
                    />
                </div>
            </>
        );
    }
}

export default DisplayEditor;
